import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { AmenityMultiCheck } from "../amenity_multi_check/Component.js";
import Select from "react-select";
import ConfirmModal from "../confirm_modal/Component.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh, data = "", view }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isAnyRoomOccupied, setIsAnyRoomOccupied] = useState(false);
  const [originalNumberOfApartments, setOriginalNumberOfApartments] = useState(null);

  const [names, setNames] = useState([]);
  const [newNames, setNewNames] = useState([]); // Names created after increasing count
  const [editedNames, setEditedNames] = useState([]); // Manually or automatically edited names
  const [deletedNames, setDeletedNames] = useState([]); // Names removed due to decreasing count

  const [isNameConfigChanged, setIsNameConfigChanged] = useState(false);

  const [lastSelectedRoomType, setLastSelectedRoomType] = useState("");

  const [amenities, setAmenities] = useState([]);
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [pricingTypes, setPricingTypes] = useState([]);

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectValues, setSelectValues] = useState({
    property_id: "",
    room_type: "",
  });

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  let roomId = data;

  useEffect(() => {
    const property = properties?.find((item) => item._id === formData.property_id) || "";
    setSelectValues((prev) => ({
      ...prev,
      property_id: { label: property.title, value: property._id },
    }));
  }, [formData.property_id, properties]);

  useEffect(() => {
    const selectedType = roomTypes?.find((item) => item._id === formData.room_type);
    setSelectValues((prev) => ({
      ...prev,
      room_type: selectedType ? { label: selectedType.name, value: selectedType._id } : "",
    }));

    if (formData.room_type) {
      fetchMastersRoomTypeData(formData.room_type);
    }
  }, [formData.room_type, roomTypes]);

  // to fetch masters data
  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        urlName: hotelId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.apartment_amenity_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const propertiesResponse = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const roomTypesResponse = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const pricingTypesResponse = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok || !propertiesResponse.ok || !roomTypesResponse.ok || !pricingTypesResponse) {
        throw new Error("Network response was not ok");
      }

      const amenitiesData = await response.json();
      const propertiesData = await propertiesResponse.json();
      const roomTypesData = await roomTypesResponse.json();
      const pricingTypesData = await pricingTypesResponse.json();

      setAmenities(amenitiesData.Data);
      setProperties(propertiesData.Data);
      setRoomTypes(roomTypesData.Data);
      setPricingTypes(pricingTypesData.Data);

      // setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // for edit modal ---------------------------------------------------------------------------------
  useEffect(() => {
    if (isModalOpen) {
      fetchRoomData(roomId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, roomId, pricingTypes, view]);

  const fetchRoomData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.room_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        console.log("room dataaaa", response_data);

        const mappedAmenities = pricingTypes.map((type) => {
          const matchingAmenities = response_data?.amenities?.find((apartmentAmenity) => apartmentAmenity.category === type._id);

          return {
            category: type._id,
            amenities: matchingAmenities ? matchingAmenities.amenities : [],
          };
        });

        const roomNamesResponse = await fetch(url + clientpoints.room_name_view, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({ urlName: hotelId, filters: { room_id: id } }),
        });

        const roomNamesData = await roomNamesResponse.json();

        const roomNames = roomNamesData.Data.map((item) => ({ id: item._id, name: item.name, isEdited: false }));

        setNames(roomNames);
        setIsAnyRoomOccupied(roomNamesData.isAnyRoomOccupied);

        setDate(response_data);
        setCurrent_id(response_data._id);
        setLastSelectedRoomType(response_data.room_type);
        setFormData({
          ...formData,
          ...response_data,
          property_id: response_data.property_id._id,
          amenities: mappedAmenities,
        });

        setOriginalNumberOfApartments(response_data.num_of_apartments);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const fetchMastersRoomTypeData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(client_prefix);
      }

      const data = await response.json();

      console.log("masters room type data", data);

      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        if (lastSelectedRoomType && lastSelectedRoomType?.toString !== id) {
          setFormData({
            ...formData,
            suffix: response_data.suffix,
            prefix: response_data.prefix,
            increment_type: response_data.increment_type,
          });
        } else {
          setFormData({
            ...formData,
            suffix: formData.suffix || response_data.suffix,
            prefix: formData.prefix || response_data.prefix,
            increment_type: formData.increment_type || response_data.increment_type,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  // ----------------------------------------------------------------

  const handleChange = (event) => {
    const { name, value } = event.target;

    // if (name === "num_of_apartments" && isAnyRoomOccupied) {
    //   setIsWarningModal(true);
    //   return;
    // }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSelectAmenities = (category, value) => {
    console.log(category, value);

    setFormData((prev) => ({
      ...prev,
      amenities: prev.amenities.map((item) => (item.category === category ? { ...item, amenities: value } : item)),
    }));
  };

  const handleSetValue = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.property_id.trim()) {
      errors.property_id = "Property is required";
      isValid = false;
    }

    if (!formData.room_type.trim()) {
      errors.room_type = "Room Type is required";
      isValid = false;
    }

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (formData.base_price == 0) {
      errors.base_price = "Base Price cannot be zero";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let apiEndpoint = clientpoints.room_edit;
    let filters = { _id: roomId };
    let requestBody = {
      urlName: hotelId,
      filters: filters,
      data: { ...formData },
    };

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + apiEndpoint, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(requestBody),
        });

        const responseData = await response.json();

        if (!responseData.Status) {
          throw new Error(responseData.Message || "Failed to update room.");
        }

        const nameData = {
          property_id: formData.property_id,
          room_id: roomId,
          urlName: hotelId,
        };

        // Add new names
        const finalNewNames = names
          .filter((name) => name.isNew)
          .map((item) => ({
            ...nameData,
            name: item.name,
          }));

        if (finalNewNames.length > 0) {
          const addRoomNamesResponse = await fetch(url + clientpoints.room_name_add, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(finalNewNames),
          });

          console.log("add names response", addRoomNamesResponse);

          if (!addRoomNamesResponse.ok) {
            const errorResponse = await addRoomNamesResponse.json();
            throw new Error(errorResponse.Message || "Failed to add room names.");
          }
        }

        if (editedNames.length > 0) {
          const finalEditedNames = editedNames.map((item) => ({ ...nameData, name: item.name, _id: item.id }));

          const editRoomNamesResponse = await fetch(url + clientpoints.room_name_edit, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(finalEditedNames),
          });

          console.log("edit room names response", editRoomNamesResponse);

          if (!editRoomNamesResponse.ok) {
            const errorResponse = await editRoomNamesResponse.json();
            throw new Error(errorResponse.Message || "Failed to edit room names.");
          }
          setEditedNames([]);
        }

        if (deletedNames.length > 0) {
          const isMongoId = (id) => /^[a-f\d]{24}$/i.test(id);

          const finalDeletedNames = deletedNames.filter((name) => isMongoId(name.id)).map((item) => ({ ...nameData, _id: item.id }));

          const deleteRoomNamesResponse = await fetch(url + clientpoints.room_name_delete, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(finalDeletedNames),
          });

          console.log("delete room names response", deleteRoomNamesResponse);

          if (!deleteRoomNamesResponse.ok) {
            const errorResponse = await deleteRoomNamesResponse.json();
            throw new Error(errorResponse.Message || "Failed to delete room names.");
          }
          setDeletedNames([]);
        }

        toast.success(responseData.Message);
        setFormData(initialFormData);

        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details correctly!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  const getIncrementalValue = (index, incrementType) => {
    if (incrementType === "Numeric") {
      return index + 1;
    }
    if (incrementType === "Alphabet") {
      const place = index + 1;
      if (place <= 26) {
        return String.fromCharCode(64 + place);
      } else {
        const firstLetter = String.fromCharCode(64 + Math.floor((place - 1) / 26));
        const secondLetter = String.fromCharCode(64 + ((place - 1) % 26) + 1);
        return firstLetter + secondLetter;
      }
    }
    return "";
  };

  const generateNames = ({ num_of_apartments, prefix, suffix, increment_type }) => {
    return Array.from({ length: num_of_apartments }, (_, index) => ({
      id: `new-${index}`, // Temp ID for new names
      name: `${prefix}${getIncrementalValue(index, increment_type)}${suffix}`,
      isNew: true,
      isEdited: false,
    }));
  };

  const autoNameChangeHandler = () => {
    console.log("use effect running--------");
    setNames((prev) => {
      const updatedNames = [...prev];
      const newNamesList = generateNames(formData);

      // Reset edited names
      const newEditedNames = [];
      updatedNames?.forEach((item, index) => {
        const newName = `${formData.prefix}${getIncrementalValue(index, formData.increment_type)}${formData.suffix}`;
        if (item.name !== newName) {
          item.name = newName;
          item.isEdited = true;

          // Add to edited names if ID is a mongoId
          if (!item?.id?.startsWith("new-")) {
            newEditedNames.push({ id: item.id, name: newName });
          }
        }
      });

      // Update the editedNames state
      setEditedNames(newEditedNames);

      // Handle additional names if the number of apartments increases
      if (newNamesList.length > updatedNames.length) {
        const additionalNames = newNamesList.slice(updatedNames.length).map((name, idx) => ({
          ...name,
          id: `new-${updatedNames.length + idx}`,
          isNew: true,
        }));
        setNewNames((prevNew) => [...prevNew, ...additionalNames]);
        return [...updatedNames, ...additionalNames];
      }

      // Handle removed names if the number of apartments decreases
      if (newNamesList.length < updatedNames.length) {
        const removed = updatedNames.slice(newNamesList.length).filter((name) => !name.isNew);
        setDeletedNames((prevDeleted) => [...prevDeleted, ...removed]);
        return updatedNames.slice(0, newNamesList.length);
      }

      return updatedNames;
    });
  };

  useEffect(() => {
    if (isNameConfigChanged) {
      console.log("name config changed---");
      autoNameChangeHandler();
    }
  }, [isNameConfigChanged, formData.prefix, formData.suffix, formData.increment_type]);

  const handleNameChange = (e, id) => {
    const { value } = e.target;

    setNames((prev) =>
      prev.map((item) => {
        if (item.id == id) {
          return { ...item, name: value, isEdited: true }; // Mark as edited
        }
        return item;
      })
    );

    setEditedNames((prevEdited) => {
      const existingEditedIndex = prevEdited.findIndex((name) => name.id === id);

      if (existingEditedIndex !== -1) {
        // Update existing edited name
        const updatedEditedNames = [...prevEdited];
        updatedEditedNames[existingEditedIndex] = { id, name: value };
        return updatedEditedNames;
      }

      // Add new edited name
      return [...prevEdited, { id, name: value }];
    });
  };

  // const finalNewNames = names.filter((name) => name.isNew);
  // const finalEditedNames = editedNames;
  // const finalDeletedNames = deletedNames;

  // console.log("final new names", finalNewNames);
  // console.log("final edited names", finalEditedNames);
  // console.log("final deleted names", finalDeletedNames);
  // console.log("names on display", names);

  const handleBlur = () => {
    setNames((prev) => {
      const newLength = Number(formData.num_of_apartments) || 1;
      const currentNames = [...prev];

      if (newLength > currentNames.length) {
        // Add new names for additional fields
        const additionalNames = Array.from({ length: newLength - currentNames.length }, (_, index) => {
          let incrementalValue;
          const baseIndex = currentNames.length + index;

          if (formData.increment_type === "Numeric") {
            incrementalValue = baseIndex + 1;
          } else if (formData.increment_type === "Alphabet") {
            const place = baseIndex + 1;
            if (place <= 26) {
              incrementalValue = String.fromCharCode(64 + place);
            } else {
              const firstLetter = String.fromCharCode(64 + Math.floor((place - 1) / 26));
              const secondLetter = String.fromCharCode(64 + ((place - 1) % 26) + 1);
              incrementalValue = firstLetter + secondLetter;
            }
          }

          return {
            id: `new-${currentNames.length + index}`,
            name: `${formData.prefix}${incrementalValue}${formData.suffix}`,
            isEdited: false,
            isNew: true,
          };

          // return `${formData.prefix}${incrementalValue}${formData.suffix}`;
        });
        setNewNames((prevNew) => [...prevNew, ...additionalNames]);
        return [...currentNames, ...additionalNames];
      } else if (newLength < currentNames.length) {
        if (isAnyRoomOccupied && newLength < originalNumberOfApartments) {
          setFormData((prev) => ({ ...prev, num_of_apartments: currentNames.length }));
          setIsWarningModal(true);
          return currentNames;
        } else {
          // Remove extra names from the end
          const toDelete = currentNames.slice(newLength);
          setDeletedNames((prevDeleted) => [...prevDeleted, ...toDelete]);

          return currentNames.slice(0, newLength);
        }
      }
      return currentNames; // If length is the same, keep as is
    });
  };

  return (
    <>
      {isWarningModal && (
        <ConfirmModal
          modalName="Alert"
          setIsModalOpen={(bool) => setIsWarningModal(bool)}
          heading={`Cannot decrease room count below ${originalNumberOfApartments}. One or more rooms are occupied.`}
        />
      )}
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className="ms-auto btn btn-primary mx-2 mt-2" onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body" style={{ maxHeight: "70vh", overflowY: "scroll", padding: "0 15px" }}>
                    <div className="">
                      {/* ------------------------------------------------------------------- */}

                      {/* --------------------------------------------------------------------- */}
                      <div className="row">
                        {/* PROPERTY TYPE-------------------------------- */}
                        <div className="col-md-12">
                          <label htmlFor="property_id" className="col-form-label">
                            Property
                          </label>
                          <Select
                            isDisabled={mode === "view"}
                            required
                            options={properties?.map((property) => ({ label: `${property.title}`, value: property._id }))}
                            value={selectValues.property_id}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                property_id: val.value,
                              }))
                            }
                            placeholder="Select Property..."
                          />
                          {errors.property_id && <div className="invalid-feedback">{errors.property_id}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="room_type" className="col-form-label">
                            Room Type
                          </label>
                          <Select
                            isDisabled={mode === "view"}
                            required
                            options={roomTypes?.map((type) => ({ label: type.name, value: type._id }))}
                            value={selectValues.room_type}
                            onChange={(val) => {
                              setFormData((prev) => ({
                                ...prev,
                                room_type: val.value,
                              }));
                              setIsNameConfigChanged(true);
                            }}
                            placeholder="Select Room Type..."
                          />
                          {errors.room_type && <div className="invalid-feedback">{errors.room_type}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="name" className="col-form-label">
                            Name
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="text"
                            className={`form-control ${errors.name ? "is-invalid" : ""}`}
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="description" className="col-form-label">
                            Description
                          </label>
                          <textarea
                            disabled={mode === "view"}
                            rows={4}
                            type="text"
                            className={`form-control ${errors.description ? "is-invalid" : ""}`}
                            name="description"
                            id="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                          {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="rating" className="col-form-label">
                            Extra Charges Type
                          </label>
                          <select
                            disabled={mode === "view"}
                            id="rating"
                            className={`form-select`}
                            value={formData.rating}
                            onChange={(e) => setFormData((prev) => ({ ...prev, rating: e.target.value }))}
                          >
                            <option value="Fixed">Fixed</option>
                            <option value="Per day">Per day</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="base_price" className="col-form-label">
                            Base Price
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control ${errors.base_price ? "is-invalid" : ""}`}
                            id="base_price"
                            name="base_price"
                            value={formData.base_price}
                            onChange={handleChange}
                          />
                          {errors.base_price && <div className="invalid-feedback">{errors.base_price}</div>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="cleaning_fee" className="col-form-label">
                            Cleaning Fee
                          </label>
                          <input disabled={mode === "view"} type="number" className={`form-control `} id="cleaning_fee" name="cleaning_fee" value={formData.cleaning_fee} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="utility_charges" className="col-form-label">
                            Utility Charges
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control `}
                            id="utility_charges"
                            name="utility_charges"
                            value={formData.utility_charges}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="electric_charges" className="col-form-label">
                            Electric Charges
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control `}
                            id="electric_charges"
                            name="electric_charges"
                            value={formData.electric_charges}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="area_measurement" className="col-form-label">
                            Area measurement
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control `}
                            id="area_measurement"
                            name="area_measurement"
                            value={formData.area_measurement}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_bedrooms" className="col-form-label">
                            Number of Bedrooms
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control `}
                            id="num_of_bedrooms"
                            name="num_of_bedrooms"
                            value={formData.num_of_bedrooms}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_beds" className="col-form-label">
                            Number of Beds
                          </label>
                          <input disabled={mode === "view"} type="number" className={`form-control `} id="num_of_beds" name="num_of_beds" value={formData.num_of_beds} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_bathrooms" className="col-form-label">
                            Number of Bathrooms
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control `}
                            id="num_of_bathrooms"
                            name="num_of_bathrooms"
                            value={formData.num_of_bathrooms}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_livingRooms" className="col-form-label">
                            Number of Living Rooms
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control `}
                            id="num_of_livingRooms"
                            name="num_of_livingRooms"
                            value={formData.num_of_livingRooms}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_adults" className="col-form-label">
                            Number of Adults
                          </label>
                          <input disabled={mode === "view"} type="number" className={`form-control `} id="num_of_adults" name="num_of_adults" value={formData.num_of_adults} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-12">
                          <label htmlFor="num_of_kids" className="col-form-label">
                            Number of Kids
                          </label>
                          <input disabled={mode === "view"} type="number" className={`form-control `} id="num_of_kids" name="num_of_kids" value={formData.num_of_kids} onChange={handleChange} />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <label>
                            <input
                              disabled={mode === "view"}
                              type="checkbox"
                              name="isActive"
                              checked={formData.isActive}
                              onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))}
                            />
                          </label>
                          Active
                        </div>{" "}
                        <div className="col-md-12 mb-2">
                          <label htmlFor="notes" className="col-form-label">
                            Notes
                          </label>
                          <textarea disabled={mode === "view"} rows={4} type="text" className={`form-control `} name="notes" id="notes" value={formData.notes} onChange={handleChange} />
                        </div>
                        <div className="priceAmenitiesContainer">
                          {formData?.amenities?.map((item, i) => (
                            <div key={i} className="md:col-span-4 col-span-12">
                              <span className="font-[600] text-gray-400">{pricingTypes.find((am) => am._id === item.category)?.name} Amenities</span>
                              <AmenityMultiCheck disabled={mode === "view"} options={amenities} selected={item.amenities} category={item.category} onCheck={handleSelectAmenities} />
                            </div>
                          ))}
                        </div>
                        <hr />
                        <h4 className="center">Rooms Mapping</h4>
                        <div className="col-md-6">
                          <label htmlFor="num_of_apartments" className="col-form-label">
                            Number of rooms
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="number"
                            className={`form-control `}
                            id="num_of_apartments"
                            name="num_of_apartments"
                            value={formData.num_of_apartments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="prefix" className="col-form-label">
                            Room Number Prefix
                          </label>
                          <input
                            disabled={mode === "view"}
                            placeholder="4"
                            type="text"
                            className={`form-control ${errors.prefix ? "is-invalid" : ""}`}
                            id="prefix"
                            name="prefix"
                            value={formData.prefix}
                            onChange={(e) => {
                              handleChange(e);
                              setIsNameConfigChanged(true);
                            }}
                          />
                          {errors.prefix && <div className="invalid-feedback">{errors.prefix}</div>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="suffix" className="col-form-label">
                            Room Number Suffix
                          </label>
                          <input
                            disabled={mode === "view"}
                            placeholder="A"
                            type="text"
                            className={`form-control ${errors.suffix ? "is-invalid" : ""}`}
                            id="suffix"
                            name="suffix"
                            value={formData.suffix}
                            onChange={(e) => {
                              handleChange(e);
                              setIsNameConfigChanged(true);
                            }}
                          />
                          {errors.suffix && <div className="invalid-feedback">{errors.suffix}</div>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="increment_type" className="col-form-label">
                            Type
                          </label>
                          <select
                            disabled={mode === "view"}
                            id="increment_type"
                            className={`form-select ${errors.increment_type ? "is-invalid" : ""}`}
                            value={formData.increment_type}
                            onChange={(e) => {
                              setFormData((prev) => ({ ...prev, increment_type: e.target.value }));
                              setIsNameConfigChanged(true);
                            }}
                          >
                            <option value="Numeric">Numeric</option>
                            <option value="Alphabet">Alphabet</option>
                          </select>
                          {errors.increment_type && <div className="invalid-feedback">{errors.increment_type}</div>}
                        </div>
                        <h5 className="mt-2">Room names</h5>
                        <div className="row">
                          {names.map((item) => (
                            <div key={item.id} className="col-md-3 mb-2">
                              <input disabled={mode === "view"} type="text" className="form-control" value={item.name} onChange={(e) => handleNameChange(e, item.id)} />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="modal-footer">
                        {view !== "view" && (
                          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                            {isSubmitting ? "Loading..." : "Submit"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const initialFormData = {
  property_id: "",
  room_type: "",
  name: "",
  description: "",
  extra_charges_type: "Fixed",
  base_price: "",
  cleaning_fee: "",
  utility_charges: "",
  electric_charges: "",
  area_measurement: "",
  isActive: true,
  num_of_apartments: 1,
  prefix: "",
  suffix: "",
  increment_type: "Numeric",
  num_of_bedrooms: "",
  num_of_beds: "",
  num_of_bathrooms: "",
  num_of_livingRooms: "",
  num_of_adults: "",
  num_of_kids: "",
  notes: "",
  amenities: [],
  images: [],
};
