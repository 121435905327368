import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";
import Select from "react-select";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [formData, setFormData] = useState(initialFormData);

  const [isPriceCalculating, setIsPriceCalculating] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [pricingTypes, setPricingTypes] = useState([]);
  const [pricingTypeVariants, setPricingTypeVariants] = useState([]);

  const [availableRooms, setAvailableRooms] = useState([]);

  const [selectedRoomName, setSelectedRoomName] = useState(null);

  const [selectedValues, setSelectedValues] = useState({
    user: "",
    area: "",
    property: "",
    room: "",
    room_type: "",
    pricingType: "",
    pricingTypeVariant: "",
    showRooms: [],
  });

  useEffect(() => {
    fetchAreas();
    fetchUsers();
    fetchPricingTypes();
    fetchRoomTypes();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ filters: {} }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setUsers(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await fetch(url + clientpoints.area_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setAreas(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchRoomTypes = async () => {
    try {
      const response = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setRoomTypes(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchPricingTypes = async () => {
    try {
      const response = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setPricingTypes(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    const area = areas.find((item) => item._id === formData.area) || "";
    const fetchProperties = async () => {
      try {
        const response = await fetch(url + clientpoints.property_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { area: area._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setProperties(data.Data);
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.area) {
      fetchProperties();
    }
  }, [formData.area, areas]);

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property_id) || "";
    const roomType = roomTypes.find((item) => item._id === formData.room_type) || "";

    const fetchRooms = async () => {
      try {
        const response = await fetch(url + clientpoints.room_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { property_id: property._id, room_type: roomType._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setRooms(data.Data);
        setSelectedValues((prev) => ({
          ...prev,
          property: property ? { label: property.title, value: property._id } : "",
          showRooms: data.Data,
        }));
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.property_id) {
      fetchRooms();
    }
  }, [formData.property_id, formData.room_type, roomTypes, properties]);

  useEffect(() => {
    const area = areas.find((item) => item._id === formData.area);
    setSelectedValues((prev) => ({
      ...prev,
      area: area ? { label: area.area_name, value: area._id } : "",
    }));
  }, [formData.area, areas]);

  useEffect(() => {
    const user = users.find((item) => item._id === formData.user_id);

    setSelectedUser(user);

    setSelectedValues((prev) => ({
      ...prev,
      user: user ? { label: user.email, value: user._id } : "",
    }));
  }, [formData.user_id, users]);

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property_id);
    setSelectedValues((prev) => ({
      ...prev,
      property: property ? { label: property.title, value: property._id } : "",
      // showApartments: property ? apartments.filter((item) => item.propertyId == property._id) : [],
    }));
    // setFormData()
  }, [formData.property_id, properties]);

  useEffect(() => {
    const roomType = roomTypes.find((item) => item._id === formData.room_type);
    setSelectedValues((prev) => ({
      ...prev,
      room_type: roomType ? { label: roomType.name, value: roomType._id } : "",
    }));
  }, [formData.room_type, roomTypes]);

  useEffect(() => {
    const room = rooms.find((item) => item._id === formData.room_id);

    setSelectedRoom(room);

    setSelectedValues((prev) => ({
      ...prev,
      room: room ? { label: room.name, value: room._id } : "",
    }));
  }, [formData.room_id, rooms]);

  useEffect(() => {
    const pricingType = pricingTypes.find((item) => item._id === formData.pricing_type_id) || "";
    // setSelectedValues((prev) => ({
    //   ...prev,
    //   pricingType: pricingType ? { label: pricingType.name, value: pricingType._id } : "",
    // }));

    const fetchPricingTypeVariants = async () => {
      try {
        const response = await fetch(url + clientpoints.pricing_type_variant_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { pricing_type_id: pricingType._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setPricingTypeVariants(data.Data);
        setSelectedValues((prev) => ({
          ...prev,
          pricingType: pricingType ? { label: pricingType.name, value: pricingType._id } : "",
        }));
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.pricing_type_id) {
      fetchPricingTypeVariants();
    }
  }, [formData.pricing_type_id, pricingTypes]);

  useEffect(() => {
    const pricingTypeVariant = pricingTypeVariants.find((item) => item._id === formData.pricing_type_variant_id);
    setSelectedValues((prev) => ({
      ...prev,
      pricingTypeVariant: pricingTypeVariant ? { label: pricingTypeVariant.name, value: pricingTypeVariant._id } : "",
    }));
  }, [formData.pricing_type_variant_id, pricingTypeVariants]);

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property_id) || "";
    const room = rooms.find((item) => item._id === formData.room_id) || "";
    const check_in = formData.check_in;
    const check_out = formData.check_out;

    const fetchAvailableRoomNames = async () => {
      try {
        const response = await fetch(url + clientpoints.room_name_available, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { property_id: property._id, room_id: room._id, check_in: check_in, check_out: check_out },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setAvailableRooms(data.Data);
        setFormData((prev) => ({
          ...prev,
          total_nights: formData.check_in && formData.check_out ? Math.ceil((new Date(formData.check_out) - new Date(formData.check_in)) / (1000 * 60 * 60 * 24)) : "",
        }));
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.property_id && formData.room_id && formData.check_in && formData.check_out) {
      fetchAvailableRoomNames();
    }
  }, [formData.check_in, formData.check_out, formData.property_id, formData.room_id]);

  useEffect(() => {
    const property = properties.find((item) => item._id == formData.property_id) || "";
    const room = rooms.find((item) => item._id == formData.room_id) || "";
    const pricingTypeVariant = pricingTypeVariants.find((item) => item._id == formData.pricing_type_variant_id) || "";
    const check_in = formData.check_in;
    const check_out = formData.check_out;
    const total_nights = formData.total_nights;

    const fetchCalculatedPrice = async () => {
      setIsPriceCalculating(true);
      try {
        const response = await fetch(url + clientpoints.booking_price_calculator, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { property_id: property._id, room_id: room._id, check_in: check_in, check_out: check_out, total_nights, pricing_type_variant_id: pricingTypeVariant._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();

        setFormData((prev) => ({
          ...prev,
          total_base_price: data.Data.total_base_price,
        }));
        setIsPriceCalculating(false);
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.property_id && formData.room_id && formData.pricing_type_variant_id && formData.check_in && formData.check_out) {
      fetchCalculatedPrice();
    }
  }, [formData.check_in, formData.check_out, formData.property_id, formData.room_id, formData.pricing_type_variant_id]);

  useEffect(() => {
    if (selectedUser) {
      setFormData((prev) => ({
        ...prev,
        billing_detail: {
          billing_name: selectedUser.billing_name,
          billing_address: selectedUser.billing_address,
          gstin: selectedUser.gstin,
          tax_type: "",
          base_amount: formData.total_base_price,
          tax_amount: formData.tax_amount,
          total_amount: formData.total_cost,
        },
      }));
    }
  }, [selectedUser, formData.total_base_price, formData.tax_amount, formData.total_cost]);

  // useEffect(() => {
  //   const baseRatePerNight = parseInt(selectedRoom?.base_price);
  //   const totalBasePrice = baseRatePerNight * parseInt(formData.total_nights);

  //   if (formData.total_nights && !formData.total_base_price) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       total_base_price: totalBasePrice,
  //     }));
  //   }
  // }, [formData.total_nights, selectedRoom]);

  useEffect(() => {
    const tax = parseInt(formData.tax_amount) || 0;
    const discount = parseInt(formData.discount_value) || 0;

    if (formData.total_base_price) {
      const discountedPrice = formData.total_base_price - discount;

      setFormData((prev) => ({
        ...prev,
        total_cost: discountedPrice + tax,
      }));
    }
  }, [formData.total_base_price, formData.tax_amount, formData.discount_value]);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.user_id.trim()) {
      errors.user_id = "User is required";
      isValid = false;
    }
    if (!formData.area.trim()) {
      errors.area = "Area is required";
      isValid = false;
    }
    if (!formData.property_id.trim()) {
      errors.property_id = "Property is required";
      isValid = false;
    }
    if (!formData.room_type.trim()) {
      errors.room_type = "Room Type is required";
      isValid = false;
    }
    if (!formData.room_id.trim()) {
      errors.room_id = "Room is required";
      isValid = false;
    }
    if (!formData.check_in.trim()) {
      errors.check_in = "Check-in date is required";
      isValid = false;
    }
    if (!formData.check_out.trim()) {
      errors.check_out = "Check-out date is required";
      isValid = false;
    }

    if (formData.guests <= 0) {
      errors.guests = "Guests cannot be zero";
      isValid = false;
    }

    if (!formData.guest_name.trim()) {
      errors.guest_name = "Guest name is required";
      isValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    }

    if (!formData.pricing_type_id.trim()) {
      errors.pricing_type_id = "Pricing Type is required";
      isValid = false;
    }

    if (!formData.pricing_type_variant_id.trim()) {
      errors.pricing_type_variant_id = "Pricing Type Variant is required";
      isValid = false;
    }

    if (formData.total_base_price <= 0) {
      errors.total_base_price = "Total Base Price cannot be zero";
      isValid = false;
    }

    if (formData.total_cost <= 0) {
      errors.total_cost = "Grand total cannot be zero";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        setIsSubmitting(true);

        let roomNameBookedId = "";

        if (!selectedRoomName?._id) {
          roomNameBookedId = availableRooms[0]._id;
        } else {
          roomNameBookedId = selectedRoomName._id;
        }

        const response = await fetch(url + clientpoints.booking_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            ...formData,
            room_name_id: roomNameBookedId,
          }),
        });

        const responseData = await response.json();

        const newBookingId = responseData._id;

        if (responseData.Status) {
          toast.success(responseData.Message);

          // if booking added successfully, add empty feedback doc
          const response = await fetch(url + clientpoints.feedback_add, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify({
              urlName: hotelId,
              booking_id: newBookingId,
              feedback: "",
            }),
          });

          const feedbackResponseData = await response.json();

          if (feedbackResponseData.Status) {
            console.log("Feedback added!");
          } else {
            console.log("Error adding feedback!");
            toast.error("Error adding feedback!");
          }
          setFormData(initialFormData);
          setIsModalOpen(false);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  const handleRoomClick = (room) => {
    if (selectedRoom && selectedRoomName?._id === room._id) {
      setSelectedRoomName(null);
    } else {
      setSelectedRoomName(room);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div
                  className="modal-body"
                  style={{
                    maxHeight: "70vh",
                    overflowY: "scroll",
                    padding: "0 15px",
                  }}
                >
                  <div className="">
                    {/* ------------------------------------------------------------------- */}

                    {/* --------------------------------------------------------------------- */}
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="user_id" className="col-form-label">
                          User
                        </label>
                        <Select
                          required
                          options={users?.map((user) => ({
                            label: `${user.name} (${user.email})`,
                            value: user._id,
                          }))}
                          value={selectedValues.user}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              user_id: val.value,
                            }))
                          }
                          placeholder="Select User..."
                        />
                        {errors.user_id && <div className="invalid-feedback">{errors.user_id}</div>}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="area" className="col-form-label">
                          Area
                        </label>
                        <Select
                          required
                          options={areas?.map((area) => ({
                            label: `${area.area_name}`,
                            value: area._id,
                          }))}
                          value={selectedValues.area}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              area: val.value,
                            }))
                          }
                          placeholder="Select Area..."
                        />
                        {errors.area && <div className="invalid-feedback">{errors.area}</div>}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="property_id" className="col-form-label">
                          Property
                        </label>
                        <Select
                          disabled={!formData.area}
                          required
                          options={properties?.map((property) => ({
                            label: `${property.title}`,
                            value: property._id,
                          }))}
                          value={selectedValues.property}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              property_id: val.value,
                            }))
                          }
                          placeholder="Select Property..."
                        />
                        {errors.property_id && <div className="invalid-feedback">{errors.property_id}</div>}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="room_type" className="col-form-label">
                          Room Type
                        </label>
                        <Select
                          disabled={!formData.property_id}
                          required
                          options={roomTypes?.map((roomType) => ({
                            label: `${roomType.name}`,
                            value: roomType._id,
                          }))}
                          value={selectedValues.room_type}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              room_type: val.value,
                            }))
                          }
                          placeholder="Select Room Type..."
                        />
                        {errors.room_type && <div className="invalid-feedback">{errors.room_type}</div>}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="room_id" className="col-form-label">
                          Room
                        </label>
                        <Select
                          disabled={!formData.room_type}
                          required
                          options={rooms?.map((room) => ({
                            label: room.name,
                            value: room._id,
                          }))}
                          value={selectedValues.room}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              room_id: val.value,
                            }))
                          }
                          placeholder="Select Room..."
                        />
                        {errors.room_id && <div className="invalid-feedback">{errors.room_id}</div>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="check_in" className="col-form-label">
                          Check-in Date
                        </label>
                        <input type="date" className={`form-control ${errors.check_in ? "is-invalid" : ""}`} id="check_in" name="check_in" value={formData.check_in} onChange={handleChange} />
                        {errors.check_in && <div className="invalid-feedback">{errors.check_in}</div>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="check_out" className="col-form-label">
                          Check-out Date
                        </label>
                        <input type="date" className={`form-control ${errors.check_out ? "is-invalid" : ""}`} id="check_out" name="check_out" value={formData.check_out} onChange={handleChange} />
                        {errors.check_out && <div className="invalid-feedback">{errors.check_out}</div>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="total_nights" className="col-form-label">
                          Total Nights
                        </label>
                        <input
                          disabled
                          type="number"
                          className={`form-control ${errors.total_nights ? "is-invalid" : ""}`}
                          id="total_nights"
                          name="total_nights"
                          value={formData.total_nights}
                          onChange={handleChange}
                        />
                        {errors.total_nights && <div className="invalid-feedback">{errors.total_nights}</div>}
                      </div>
                      <div className="mt-2">
                        <h6>
                          <strong>Available Rooms (Please select a room)</strong>
                        </h6>
                        {availableRooms.length === 0 ? (
                          <div>No rooms available</div>
                        ) : (
                          <div className="booking_add">
                            {availableRooms.map((room, index) => (
                              <div key={room._id} onClick={() => handleRoomClick(room)} className="time-slot-item">
                                <div className={`card ${selectedRoomName?._id == room._id ? "selected" : ""}`}>
                                  <h6 className="room_name">{room.name}</h6>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="guests" className="col-form-label">
                          Number of Guests
                        </label>
                        <input type="number" className={`form-control ${errors.guests ? "is-invalid" : ""}`} id="guests" name="guests" value={formData.guests} onChange={handleChange} />
                        {errors.guests && <div className="invalid-feedback">{errors.guests}</div>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="children" className="col-form-label">
                          Number of Children
                        </label>
                        <input type="number" className={`form-control ${errors.children ? "is-invalid" : ""}`} id="children" name="children" value={formData.children} onChange={handleChange} />
                        {errors.children && <div className="invalid-feedback">{errors.children}</div>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="guest_name" className="col-form-label">
                          Guest Name
                        </label>
                        <input type="text" className={`form-control ${errors.guest_name ? "is-invalid" : ""}`} name="guest_name" id="guest_name" value={formData.guest_name} onChange={handleChange} />
                        {errors.guest_name && <div className="invalid-feedback">{errors.guest_name}</div>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="col-form-label">
                          Phone
                        </label>
                        <input type="text" className={`form-control ${errors.phone ? "is-invalid" : ""}`} name="phone" id="phone" value={formData.phone} onChange={handleChange} />
                        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email" className="col-form-label">
                          Email
                        </label>
                        <input type="email" className={`form-control ${errors.email ? "is-invalid" : ""}`} name="email" id="email" value={formData.email} onChange={handleChange} />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="special_request" className="col-form-label">
                          Special Request
                        </label>
                        <textarea
                          rows={4}
                          type="text"
                          className={`form-control ${errors.special_request ? "is-invalid" : ""}`}
                          name="special_request"
                          id="special_request"
                          value={formData.special_request}
                          onChange={handleChange}
                        />
                        {errors.special_request && <div className="invalid-feedback">{errors.special_request}</div>}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="pricing_type" className="col-form-label">
                          Pricing Type
                        </label>
                        <Select
                          required
                          options={pricingTypes?.map((type) => ({
                            label: type.name,
                            value: type._id,
                          }))}
                          value={selectedValues.pricingType}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              pricing_type_id: val.value,
                            }))
                          }
                          placeholder="Select Pricing Type..."
                        />
                        {errors.pricing_type_id && <div className="invalid-feedback">{errors.pricing_type_id}</div>}
                      </div>
                      {/* <div className="col-md-12">
                        <label htmlFor="deal_type" className="col-form-label">
                          Deal Type
                        </label>
                        <select
                          id="deal_type"
                          className={`form-select`}
                          value={formData.deal_type}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              deal_type: e.target.value,
                            }))
                          }
                        >
                          {rateCategories.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div> */}
                      <div className="col-md-12">
                        <label htmlFor="pricing_type_variant_id" className="col-form-label">
                          Pricing Type Variant
                        </label>
                        <Select
                          required
                          options={pricingTypeVariants?.map((type) => ({
                            label: type.name,
                            value: type._id,
                          }))}
                          value={selectedValues.pricingTypeVariant}
                          onChange={(val) =>
                            setFormData((prev) => ({
                              ...prev,
                              pricing_type_variant_id: val.value,
                            }))
                          }
                          placeholder="Select Pricing Type Variant..."
                        />
                        {errors.pricing_type_variant_id && <div className="invalid-feedback">{errors.pricing_type_variant_id}</div>}
                      </div>
                      {/* <div className="col-md-12">
                        <label htmlFor="cancellation_type" className="col-form-label">
                          Cancellation Type
                        </label>
                        <select
                          disabled
                          id="cancellation_type"
                          className={`form-select`}
                          value={formData.cancellation_type}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              cancellation_type: e.target.value,
                            }))
                          }
                        >
                          {cancellationTypes.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div> */}
                      {!isPriceCalculating ? (
                        <>
                          {" "}
                          <div className="col-md-6">
                            <label htmlFor="total_base_price" className="col-form-label">
                              Total Base Price
                            </label>
                            <input
                              type="number"
                              className={`form-control ${errors.total_base_price ? "is-invalid" : ""}`}
                              id="total_base_price"
                              name="total_base_price"
                              value={formData.total_base_price}
                              onChange={handleChange}
                            />
                            {errors.total_base_price && <div className="invalid-feedback">{errors.total_base_price}</div>}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="discount_value" className="col-form-label">
                              Discount Value
                            </label>
                            <input type="number" className={`form-control `} id="discount_value" name="discount_value" value={formData.discount_value} onChange={handleChange} />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="tax_amount" className="col-form-label">
                              Tax Amount
                            </label>
                            <input type="number" className={`form-control `} id="tax_amount" name="tax_amount" value={formData.tax_amount} onChange={handleChange} />
                          </div>{" "}
                          <div className="col-md-6">
                            <label htmlFor="total_cost" className="col-form-label">
                              Grand Total
                            </label>
                            <input type="number" className={`form-control `} id="total_cost" name="total_cost" value={formData.total_cost} onChange={handleChange} />
                          </div>
                        </>
                      ) : (
                        <h6 className="center mt-5">Getting price details...</h6>
                      )}{" "}
                      <div className="col-md-12">
                        <label htmlFor="payment_mode" className="col-form-label">
                          Payment Mode
                        </label>
                        <select
                          id="payment_mode"
                          className={`form-select`}
                          value={formData.payment_mode}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              payment_mode: e.target.value,
                            }))
                          }
                        >
                          {paymentModes.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="status" className="col-form-label">
                          Booking Status
                        </label>
                        <select
                          id="status"
                          className={`form-select`}
                          value={formData.status}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }))
                          }
                        >
                          {bookingStatus.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

export const bookingStatus = ["pending", "completed", "cancelled"];
export const rateCategories = ["Hot Deal", "Best Available Rate"];
export const paymentModes = ["UPI", "Card", "Net Banking"];
export const cancellationTypes = ["nonRefundable", "refundable"];

const initialFormData = {
  user_id: "",
  area: "",
  property_id: "",
  room_id: "",
  room_type: "",
  pricing_type_id: "",
  check_in: "",
  check_out: "",
  total_nights: 0,
  guests: 1,
  children: 0,
  guest_name: "",
  phone: "",
  email: "",
  special_request: "",
  payment_mode: paymentModes[0],
  cancellation_type: cancellationTypes[0],
  deal_type: rateCategories[0],
  total_base_price: 0,
  discount_value: 0,
  discount_type: "",
  promo_discount_value: "",
  promo_code: "",
  tax_amount: 0,
  total_cost: 0,
  status: bookingStatus[0],
  billing_detail: {
    billing_name: "",
    billing_address: "",
    gstin: "",
    tax_type: "",
    base_amount: 0,
    tax_amount: 0,
    total_amount: 0,
  },
};
