import React, { useState, useEffect, useRef } from "react";
import "./component.css"; // Import your CSS file
import { Link, useLocation, useNavigate } from "react-router-dom";
import { url, clientpoints } from "../../../../../lib/lib.js";

function Component({ roleObject = "", role = "" }) {
  const sidebarRef = useRef();
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDrop, setActiveDrop] = useState("");
  const { pathname } = useLocation();
  const isAnyDropdownOptionActive = () => {
    return (
      pathname.includes("masters/property-types") ||
      pathname.includes("masters/room-types") ||
      pathname.includes("masters/pricing-types") ||
      pathname.includes("masters/property-amenities") ||
      pathname.includes("masters/apartment-amenities") ||
      pathname.includes("masters/faqs")
    );
  };
  const isAnyDropdownOptionActive1 = () => {
    return pathname.includes("resources/areas") || pathname.includes("resources/properties") || pathname.includes("resources/rooms");
  };
  const isAnyDropdownOptionActive2 = () => {
    // return (
    //   // pathname.includes("resources/locations") ||
    //   // pathname.includes("resources/floors") ||
    //   // pathname.includes("resources/assets-mapping")
    // );
  };

  useEffect(() => {
    setActiveDrop(pathname);
  }, [pathname]);

  const [mastersDropdownOpen1, setMastersDropdownOpen1] = useState(false);
  const [mastersDropdownOpen, setMastersDropdownOpen] = useState(false);

  const [mastersDropdownOpeninvoice, setMastersDropdownOpeninvoice] = useState(false);
  const [mastersDropdownOpenexpense, setMastersDropdownOpenexpense] = useState(false);
  const [mastersDropdownOpenvisitor, setMastersDropdownOpenvisitor] = useState(false);
  const [mastersDropdownOpenOffer, setMastersDropdownOpenOffer] = useState(false);
  const [mastersDropdownOpenenqurie, setMastersDropdownOpenenqurie] = useState(false);
  const [mastersDropdownOpenoccupancy, setMastersDropdownOpenoccupancy] = useState(false);
  const [mastersDropdownOpenlocation, setMastersDropdownOpenlocation] = useState(false);
  const [mastersDropdownOpensetting, setMastersDropdownOpensetting] = useState(false);
  const [mastersDropdownOpenbooking, setMastersDropdownOpenbooking] = useState(false);
  const [mastersDropdownOpensupport, setMastersDropdownOpensupport] = useState(false);
  const [mastersDropdownOpenclient, setMastersDropdownOpenclient] = useState(false);
  const [mastersDropdownOpencommunity, setMastersDropdownOpencommunity] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // If the click is outside the sidebar, close the sidebar
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);
  const toggleDropdown = () => {
    setMastersDropdownOpen(!mastersDropdownOpen);
  };
  const toggleDropdowninvoice = () => {
    setMastersDropdownOpeninvoice(!mastersDropdownOpeninvoice);
  };
  const toggleDropdownexpense = () => {
    setMastersDropdownOpenexpense(!mastersDropdownOpenexpense);
  };
  const toggleDropdownvisitor = () => {
    setMastersDropdownOpenvisitor(!mastersDropdownOpenvisitor);
  };
  const toggleDropdownOffer = () => {
    setMastersDropdownOpenOffer(!mastersDropdownOpenOffer);
  };
  const toggleDropdownenqurie = () => {
    setMastersDropdownOpenenqurie(!mastersDropdownOpenenqurie);
  };
  const toggleDropdownlocation = () => {
    setMastersDropdownOpenlocation(!mastersDropdownOpenlocation);
  };
  const toggleDropdown1 = () => {
    setMastersDropdownOpen1(!mastersDropdownOpen1);
  };
  const toggleDropdownoccupancy = () => {
    setMastersDropdownOpenoccupancy(!mastersDropdownOpenoccupancy);
  };
  const toggleDropdownsetting = () => {
    setMastersDropdownOpensetting(!mastersDropdownOpensetting);
  };
  const toggleDropdownbooking = () => {
    setMastersDropdownOpenbooking(!mastersDropdownOpenbooking);
  };
  const toggleDropdownsupport = () => {
    setMastersDropdownOpensupport(!mastersDropdownOpensupport);
  };
  const toggleDropdownclient = () => {
    setMastersDropdownOpenclient(!mastersDropdownOpenclient);
  };
  const toggleDropdowncommunity = () => {
    setMastersDropdownOpencommunity(!mastersDropdownOpencommunity);
  };

  const handleLogout = async () => {
    if (role === "team") {
      localStorage.clear();
      try {
        let response = await fetch(url + clientpoints.team_logout, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        navigate("/login");
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred during logout.");
      }
    } else if (role === "client") {
      localStorage.clear();
      try {
        let response = await fetch(url + clientpoints.logout, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        navigate("/login");
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred during logout.");
      }
    }
  };

  return (
    <div>
      <div id="mySidenav" className={`sidenav ${isSidebarOpen ? "open" : ""}`} ref={sidebarRef}>
        <a href="javascript:void(0)" className="closebtn" onClick={() => setIsSidebarOpen(false)}>
          &times;
        </a>
        <div className="desk-flx">
          <img src="/img/stay_white.png" className="img-fluid logo" alt="staystation Logo" />
        </div>

        {/* <div className="side-flx">
          <div className="side-flx1">
            <img src="/img/profile.png" className="img-fluid profile" alt="Profile" />
          </div>
          <div className="side-flx1">
            <p className="side-txt">Amit Kumar</p>
            <p className="side-txt1">Active <i className="mstr ri-checkbox-blank-circle-fill" style={{ color: "green", fontSize: "10px", marginLeft: "7px", marginTop: "2.5px" }}></i></p>
          </div>
        </div> */}

        <div className="top-marginn">
          <Link to="dashboard" className={pathname.includes("dashboard") ? `pages active` : "pages"}>
            <i className="mstr ri-home-4-line"></i>Dashboard
          </Link>
          <div className="dropdown">
            <Link className={`pages ${isAnyDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdown}>
              <i className="mstr ri-archive-stack-line mstr"></i>Masters <i className={mastersDropdownOpen ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpen ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.property_type?.view && (
                    <Link to="masters/property-types" className={[pathname.includes("masters/property-types") ? `pages1 active` : "pages1"]}>
                      <i className="mstr ri-circle-fill fillicn"></i>Property Types
                    </Link>
                  )}
                  {roleObject?.room_type?.view && (
                    <Link to="masters/room-types" className={pathname.includes("masters/room-types") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Room Types
                    </Link>
                  )}
                  {roleObject?.pricing_type?.view && (
                    <Link to="masters/pricing-types" className={pathname.includes("masters/pricing-types") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Pricing Types
                    </Link>
                  )}{" "}
                  {roleObject?.property_amenity?.view && (
                    <Link to="masters/property-amenities" className={pathname.includes("masters/property-amenities") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Property Amenities
                    </Link>
                  )}{" "}
                  {roleObject?.apartment_amenity?.view && (
                    <Link to="masters/apartment-amenities" className={pathname.includes("masters/apartment-amenities") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Apartment Amenities
                    </Link>
                  )}{" "}
                  {roleObject?.faq?.view && (
                    <Link to="masters/faqs" className={pathname.includes("masters/faqs") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Faqs
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link to="masters/property-types" className={[pathname.includes("masters/property-types") ? `pages1 active` : "pages1"]}>
                    <i className="mstr ri-circle-fill fillicn"></i>Property Types
                  </Link>
                  <Link to="masters/room-types" className={pathname.includes("masters/room-types") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Room Types
                  </Link>
                  <Link to="masters/pricing-types" className={pathname.includes("masters/pricing-types") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Pricing Types
                  </Link>
                  <Link to="masters/property-amenities" className={pathname.includes("masters/property-amenities") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Property Amenities
                  </Link>
                  <Link to="masters/apartment-amenities" className={pathname.includes("masters/apartment-amenities") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Apartment Amenities
                  </Link>
                  <Link to="masters/faqs" className={pathname.includes("masters/faqs") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Faqs
                  </Link>
                </>
              )}
            </div>
          </div>
          {/* <a href="#" className="pages"><i className="mstr ri-map-pin-line "></i>Locations</a> */}

          <div className="dropdown">
            <a className={`pages ${isAnyDropdownOptionActive1() ? "active" : ""}`} onClick={toggleDropdownlocation}>
              <i className="mstr ri-map-pin-line"></i>Resources <i className={mastersDropdownOpenlocation ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </a>
            <div className={`dropdown-options ${mastersDropdownOpenlocation ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.area?.view && (
                    <Link to="resources/areas" className={pathname.includes("resources/areas") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Areas
                    </Link>
                  )}
                  {roleObject?.property?.view && (
                    <Link to="resources/properties" className={pathname.includes("resources/properties") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Properties
                    </Link>
                  )}
                  {roleObject?.room?.view && (
                    <Link to="resources/rooms" className={pathname.includes("resources/rooms") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Rooms
                    </Link>
                  )}
                  {roleObject?.room_inventory_price?.view && (
                    <Link to="resources/inventory" className={pathname.includes("resources/inventory") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Inventory
                    </Link>
                  )}
                  {roleObject?.review?.view && (
                    <Link to="resources/reviews" className={pathname.includes("resources/reviews") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Reviews
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link to="resources/areas" className={pathname.includes("resources/areas") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Areas
                  </Link>
                  <Link to="resources/properties" className={pathname.includes("resources/properties") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Properties
                  </Link>
                  <Link to="resources/rooms" className={pathname.includes("resources/rooms") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Rooms
                  </Link>
                  <Link to="resources/inventory" className={pathname.includes("resources/inventory") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Inventory
                  </Link>
                  <Link to="resources/reviews" className={pathname.includes("resources/reviews") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Reviews
                  </Link>
                </>
              )}
            </div>
          </div>
          {/* <a href="#" className="pages"><i className="mstr ri-team-line"></i></a> */}

          <div className="dropdown">
            <Link className={`pages ${isAnyDropdownOptionActive2() ? "active" : ""}`} onClick={toggleDropdown1}>
              <i className="mstr ri-team-line"></i>Team <i className={mastersDropdownOpen1 ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpen1 ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.role?.view && (
                    <Link id="role_view" to="team/roles" className="pages1">
                      <i className="mstr ri-circle-fill fillicn"></i>Roles
                    </Link>
                  )}
                  {roleObject?.team?.view && (
                    <Link id="team_view" to="team/staff" className="pages1">
                      <i className="mstr ri-circle-fill fillicn"></i>Staff
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id="role_view" to="team/roles" className="pages1">
                    <i className="mstr ri-circle-fill fillicn"></i>Roles
                  </Link>
                  <Link id="team_view" to="team/staff" className="pages1">
                    <i className="mstr ri-circle-fill fillicn"></i>Staff
                  </Link>
                </>
              )}
              {/* <Link to='team/attendance' className='pages1'><i className='mstr ri-circle-fill fillicn'></i>Attendance</Link> */}
            </div>
          </div>

          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpenclient ? "open" : ""}`} onClick={toggleDropdownclient}>
              <i className="mstr ri-group-line"></i>Clients <i className={mastersDropdownOpenclient ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenclient ? "open" : ""}`}>
              {/* Dropdown options go here */}
              {role === "team" ? (
                <>
                  {roleObject?.user?.view && (
                    <Link to="client/all-clients" className="pages1">
                      <i className="mstr ri-circle-fill fillicn"></i>All Clients
                    </Link>
                  )}
                </>
              ) : (
                <Link to="client/all-clients" className="pages1">
                  <i className="mstr ri-circle-fill fillicn"></i>All Clients
                </Link>
              )}

              {/* <Link to='client/companies' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Companies
              </Link>
              <Link to='client/members' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Members
              </Link>
              <Link to='client/contracts' className='pages1'>
                <i className='mstr ri-circle-fill fillicn '></i>Contracts
              </Link>
              <Link to='client/credits-history' className='pages1'>
                <i className='mstr ri-circle-fill fillicn '></i>Credits History
              </Link>
              <Link to='client/parking' className='pages1'>
                <i className='mstr ri-circle-fill fillicn '></i>Parking
              </Link> */}
            </div>
          </div>

          {/* <Link href="#" className="pages"><i className="mstr ri-file-list-3-line"></i>Invoices</Link> */}
          {/* <div className='dropdown'>
            <Link className={`pages ${mastersDropdownOpeninvoice ? "open" : ""}`} onClick={toggleDropdowninvoice}>
              <i className='mstr ri-file-list-3-line mstr'></i>Invoices <i className={mastersDropdownOpeninvoice ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpeninvoice ? "open" : ""}`}>
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>All Invoices
              </Link>
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Pending Invoices
              </Link>
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Paid Invoices
              </Link>
            </div>
          </div> */}

          {/* <div className='dropdown'>
            <Link className={`pages ${mastersDropdownOpenoccupancy ? "open" : ""}`} onClick={toggleDropdownoccupancy}>
              <i className='mstr ri-bill-line'></i>Occupancy <i className={mastersDropdownOpenoccupancy ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenoccupancy ? "open" : ""}`}>
              <Link to='occupancy/reports' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Overview
              </Link>
              
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Analytics{" "}
              </Link>
             
            </div>
          </div> */}

          {/* <Link href="#" className="pages"><i className="mstr ri-group-2-line"></i> Community</Link> */}
          {/* <div className='dropdown'>
            <Link className={`pages ${mastersDropdownOpencommunity ? "open" : ""}`} onClick={toggleDropdowncommunity}>
              <i className='mstr ri-group-2-line'></i>Community <i className={mastersDropdownOpencommunity ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpencommunity ? "open" : ""}`}>
              <Link to='community/posts' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Posts
              </Link>
              <Link to='community/events' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Events
              </Link>
              <Link to='community/offers' className='pages1'>
                <i className='mstr ri-circle-fill fillicn '></i>Offers
              </Link>
              <Link to='community/bulk-email' className='pages1'>
                <i className='mstr ri-circle-fill fillicn '></i>Bulk Email
              </Link>
            </div>
          </div> */}

          {/* <Link href="#" className="pages"><i className="mstr ri-calendar-event-line"></i>Bookings</Link> */}
          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpenbooking ? "open" : ""}`} onClick={toggleDropdownbooking}>
              <i className="mstr ri-calendar-event-line"></i>Bookings <i className={mastersDropdownOpenbooking ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenbooking ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.booking?.view && (
                    <Link className="pages1" to="bookings/all-bookings">
                      <i className="mstr ri-circle-fill fillicn"></i>All Bookings
                    </Link>
                  )}
                </>
              ) : (
                <Link className="pages1" to="bookings/all-bookings">
                  <i className="mstr ri-circle-fill fillicn"></i>All Bookings
                </Link>
              )}

              <Link className="pages1" to="bookings/feedbacks">
                <i className="mstr ri-circle-fill fillicn"></i>Feedbacks
              </Link>

              {/* <Link className='pages1' to='bookings/day-pass'>
                <i className='mstr ri-circle-fill fillicn'></i>Day Pass
              </Link> */}
            </div>
          </div>

          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpenOffer ? "open" : ""}`} onClick={toggleDropdownOffer}>
              <i className="mstr ri-user-follow-fill mstr"></i>Offers <i className={mastersDropdownOpenOffer ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenOffer ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.coupon?.view && (
                    <Link className="pages1" to="offers/coupons">
                      <i className="mstr ri-circle-fill fillicn"></i>Coupons
                    </Link>
                  )}
                </>
              ) : (
                <Link className="pages1" to="offers/coupons">
                  <i className="mstr ri-circle-fill fillicn"></i>Coupons
                </Link>
              )}
            </div>
          </div>

          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpenvisitor ? "open" : ""}`} onClick={toggleDropdownvisitor}>
              <i className="mstr ri-user-follow-fill mstr"></i>Visitors <i className={mastersDropdownOpenvisitor ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenvisitor ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.visitor?.view && (
                    <Link className="pages1" to="visitors/all-visitors">
                      <i className="mstr ri-circle-fill fillicn"></i>All Visitors
                    </Link>
                  )}
                </>
              ) : (
                <Link className="pages1" to="visitors/all-visitors">
                  <i className="mstr ri-circle-fill fillicn"></i>All Visitors
                </Link>
              )}
            </div>
          </div>

          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpenenqurie ? "open" : ""}`} onClick={toggleDropdownenqurie}>
              <i className="mstr ri-article-fill mstr"></i>Enquiries <i className={mastersDropdownOpenenqurie ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenenqurie ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.enquiry?.view && (
                    <Link className="pages1" to="enquiries/all-enquiries">
                      <i className="mstr ri-circle-fill fillicn"></i>All Enquiries
                    </Link>
                  )}
                </>
              ) : (
                <Link className="pages1" to="enquiries/all-enquiries">
                  <i className="mstr ri-circle-fill fillicn"></i>All Enquiries
                </Link>
              )}

              {/* <Link className='pages1'><i className='mstr ri-circle-fill fillicn'></i>Pending Enquiries</Link> */}
              {/* <Link href="#" className="pages1"><i className="mstr ri-circle-fill fillicn"></i>Converted Enquries</Link> */}
            </div>
          </div>

          {/* <Link href="#" className="pages"><i className="mstr ri-money-rupee-circle-line"></i>Expenses</Link> */}
          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpenexpense ? "open" : ""}`} onClick={toggleDropdownexpense}>
              <i className="mstr ri-money-rupee-circle-line mstr"></i>Expenses <i className={mastersDropdownOpenexpense ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenexpense ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.expense?.view && (
                    <Link className="pages1" to="expenses/all-expenses">
                      <i className="mstr ri-circle-fill fillicn"></i>All Expenses
                    </Link>
                  )}
                </>
              ) : (
                <Link className="pages1" to="expenses/all-expenses">
                  <i className="mstr ri-circle-fill fillicn"></i>All Expenses
                </Link>
              )}

              {/* <Link className='pages1'><i className='mstr ri-circle-fill fillicn'></i>Pending Expenses</Link>
              <Link className='pages1'><i className='mstr ri-circle-fill fillicn'></i>Paid Expenses</Link> */}
            </div>
          </div>

          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpensupport ? "open" : ""}`} onClick={toggleDropdownsupport}>
              <i className="mstr ri-customer-service-2-line"></i>Support <i className={mastersDropdownOpensupport ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpensupport ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.ticket?.view && (
                    <Link className="pages1" to="support/client-support">
                      <i className="mstr ri-circle-fill fillicn"></i>Clients Support
                    </Link>
                  )}
                  {roleObject?.client_ticket?.view && (
                    <Link className="pages1" to="support/staystation-support">
                      <i className="mstr ri-circle-fill fillicn"></i>StayStation Support{" "}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link className="pages1" to="support/client-support">
                    <i className="mstr ri-circle-fill fillicn"></i>Clients Support
                  </Link>
                  <Link className="pages1" to="support/staystation-support">
                    <i className="mstr ri-circle-fill fillicn"></i>StayStation Support{" "}
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* <Link href="#" className="pages"><i className="mstr ri-settings-3-line"></i></Link> */}

          <div className="dropdown">
            <Link className={`pages ${mastersDropdownOpensetting ? "open" : ""}`} onClick={toggleDropdownsetting}>
              <i className="mstr ri-settings-3-line"></i>Settings <i className={mastersDropdownOpensetting ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpensetting ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.hotel?.view && (
                    <Link className="pages1" to="settings/profile">
                      <i className="mstr ri-circle-fill fillicn"></i>Profile
                    </Link>
                  )}
                  {roleObject?.subscription?.view && (
                    <Link className="pages1" to="settings/subscription">
                      <i className="mstr ri-circle-fill fillicn"></i>
                      Subscription{" "}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link className="pages1" to="settings/profile">
                    <i className="mstr ri-circle-fill fillicn"></i>Profile
                  </Link>
                  <Link className="pages1" to="settings/subscription">
                    <i className="mstr ri-circle-fill fillicn"></i>Subscription{" "}
                  </Link>
                </>
              )}

              {/* <Link className='pages1' to='settings/activity-logs'><i className='mstr ri-circle-fill fillicn '></i>Activity Logs</Link> */}
            </div>
          </div>
          <Link className="pages" onClick={handleLogout}>
            <i className="mstr ri-shut-down-line"></i>Logout
          </Link>
          {/* <Link href="#" className="pages"><i className="mstr ri-customer-service-2-line"></i>Support</Link> */}
        </div>
      </div>

      <div className="logo-flx">
        <div className="logo-flx1">
          <img src="/img/staystation.png" className="img-fluid logo2" alt="staystation Logo" />
        </div>
        <div className="logo-flx2">
          <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={toggleSidebar}>
            &#9776;
          </span>
        </div>
      </div>
    </div>
  );
}

export default Component;
