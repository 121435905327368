import Feedbacks from "../../components/feedback/all_feedback/table/Component";
import { toast, ToastContainer } from "react-toastify";

export default function Component() {
  return (
    <>
      <ToastContainer />
      <Feedbacks name="Feedbacks" />
    </>
  );
}
