import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import SearchableSelectComponentProperty from "../property_select/Component.js";
import SearchableSelectComponentUser from "../user_select/Component.js";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [propertyName, setPropertyName] = useState("");
  const [userName, setUserName] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    check_in: "",
    check_out: "",
    // reason: "",
    description: "",
  });

  const [propertyList, setPropertyList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  const [searchValueUser, setSearchValueUser] = useState("");
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [showDropdownUser, setShowDropdownUser] = useState("");

  // useEffect(() => {
  //   fetchClientData();
  // }, []);

  // const fetchClientData = async () => {
  //   try {
  //     const response = await fetch(url + clientpoints.property_view, {
  //       method: "POST",
  //       headers: pageHeaders,
  //       body: JSON.stringify({ filters: { hotel_id: hotelId } }),
  //       credentials: "include",
  //     });
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch client data");
  //     }
  //     const data = await response.json();
  //     setPropertyList(data.Data);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!propertyName.trim()) {
      errors.property = "Property is required";
      isValid = false;
    }
    if (!userName.trim()) {
      errors.user = "User is required";
      isValid = false;
    }
    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
      isValid = false;
    }
    if (!formData.check_in.trim()) {
      errors.check_in = "Check In is required";
      isValid = false;
    }
    // if (!formData.reason.trim()) {
    //   errors.reason = "Reason is required";
    //   isValid = false;
    // }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.visitor_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            property_id: propertyName,
            user_id: userName,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            check_in: formData.check_in,
            check_out: formData.check_out,
            // reason: formData.reason,
            description: formData.description,
          }),
        });
        setPropertyName("");
        setUserName("");
        setFormData({
          name: "",
          email: "",
          phone: "",
          check_in: "",
          check_out: "",
          // reason: "",
          description: "",
        });
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPropertyName("");
    setUserName("");
    setFormData({
      name: "",
      email: "",
      phone: "",
      check_in: "",
      check_out: "",
      // reason: "",
      description: "",
    });
  };
  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  <div>
                    <label htmlFor='property' className='col-form-label'>
                      Select Property
                    </label>
                    <SearchableSelectComponentProperty
                      setPropertyName={setPropertyName}
                      searchValueProps={{ searchValue, setSearchValue }}
                      suggestionsProps={{ suggestions, setSuggestions }}
                      showDropdownProps={{ showDropdown, setShowDropdown }}
                      form={false}
                    />
                    {errors.property && <div className='invalid-feedback'>{errors.property}</div>}
                  </div>

                  <div>
                    <label htmlFor='user' className='col-form-label'>
                      Select User
                    </label>
                    <SearchableSelectComponentUser
                      setUserName={setUserName}
                      searchValueProps={{ searchValueUser, setSearchValueUser }}
                      suggestionsProps={{ suggestionsUser, setSuggestionsUser }}
                      showDropdownProps={{ showDropdownUser, setShowDropdownUser }}
                      form={false}
                    />
                    {errors.user && <div className='invalid-feedback'>{errors.user}</div>}
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor='name' className='col-form-label'>
                        Name
                      </label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='email' className='col-form-label'>
                        Email
                      </label>
                      <input type='email' className={`form-control ${errors.email ? "is-invalid" : ""}`} id='email' name='email' value={formData.email} onChange={handleChange} />
                      {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='phone' className='col-form-label'>
                        Phone
                      </label>
                      <input type='text' className={`form-control ${errors.phone ? "is-invalid" : ""}`} id='phone' name='phone' value={formData.phone} onChange={handleChange} />
                      {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='check_in' className='col-form-label'>
                        Check-in Date
                      </label>
                      <input type='datetime-local' className={`form-control ${errors.check_in ? "is-invalid" : ""}`} id='check_in' name='check_in' value={formData.check_in} onChange={handleChange} />
                      {errors.check_in && <div className='invalid-feedback'>{errors.check_in}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='check_out' className='col-form-label'>
                        Check-out Date
                      </label>
                      <input
                        type='datetime-local'
                        className={`form-control ${errors.check_out ? "is-invalid" : ""}`}
                        id='check_out'
                        name='check_out'
                        value={formData.check_out}
                        onChange={handleChange}
                      />
                      {errors.check_out && <div className='invalid-feedback'>{errors.check_out}</div>}
                    </div>

                    {/* <div className='col-md-6'>
                      <label htmlFor='reason' className='col-form-label'>
                        Reason
                      </label>
                      <input type='text' className={`form-control ${errors.reason ? "is-invalid" : ""}`} id='reason' name='reason' value={formData.reason} onChange={handleChange} />
                      {errors.reason && <div className='invalid-feedback'>{errors.reason}</div>}
                    </div> */}

                    <div className='col-md-12'>
                      <label htmlFor='description' className='col-form-label'>
                        Description
                      </label>
                      <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                      {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
