import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "", //
    code: "", //
    description: "",
    type: "fixed", //
    discount_value: 0, //
    min_billing_amount: 0,
    uses_per_customer_allowed: 0,
    total_uses_allowed: 0,
    total_uses: 0,
    start_date: "",
    end_date: "",
    isActive: true,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.code.trim()) {
      errors.code = "Code is required";
      isValid = false;
    }
    if (formData.discount_value <= 0) {
      errors.discount_value = "Discount value cannot be zero";
      isValid = false;
    }
    if (formData.uses_per_customer_allowed <= 0) {
      errors.uses_per_customer_allowed = "Uses per customer cannot be zero";
      isValid = false;
    }
    if (formData.total_uses_allowed <= 0) {
      errors.total_uses_allowed = "Total uses cannot be zero";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.coupon_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            ...formData,
          }),
        });
        setFormData(initialFormData);
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body' style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor='name' className='col-form-label'>
                        Name
                      </label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='code' className='col-form-label'>
                        Code
                      </label>
                      <input type='text' className={`form-control ${errors.code ? "is-invalid" : ""}`} id='code' name='code' value={formData.code} onChange={handleChange} />
                      {errors.code && <div className='invalid-feedback'>{errors.code}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='description' className='col-form-label'>
                        Description
                      </label>
                      <textarea className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                      {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='type' className='col-form-label'>
                        Type:
                      </label>
                      <select id='type' className={`form-select ${errors.type ? "is-invalid" : ""}`} value={formData.type} onChange={(e) => setFormData((prev) => ({ ...prev, type: e.target.value }))}>
                        <option value='fixed'>Fixed</option>
                        <option value='percentage'>Percentage</option>
                      </select>
                      {errors.type && <div className='invalid-feedback'>{errors.type}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='discount_value' className='col-form-label'>
                        Discount Value
                      </label>
                      <input
                        type='number'
                        className={`form-control ${errors.discount_value ? "is-invalid" : ""}`}
                        id='discount_value'
                        name='discount_value'
                        value={formData.discount_value}
                        onChange={handleChange}
                      />
                      {errors.discount_value && <div className='invalid-feedback'>{errors.discount_value}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='min_billing_amount' className='col-form-label'>
                        Minimum Billing Amount
                      </label>
                      <input
                        type='number'
                        className={`form-control ${errors.min_billing_amount ? "is-invalid" : ""}`}
                        id='min_billing_amount'
                        name='min_billing_amount'
                        value={formData.min_billing_amount}
                        onChange={handleChange}
                      />
                      {errors.min_billing_amount && <div className='invalid-feedback'>{errors.min_billing_amount}</div>}
                    </div>

                    <div className='col=md-6'></div>

                    <div className='col-md-6'>
                      <label htmlFor='start_date' className='col-form-label'>
                        Start Date
                      </label>
                      <input type='date' className={`form-control ${errors.start_date ? "is-invalid" : ""}`} id='start_date' name='start_date' value={formData.start_date} onChange={handleChange} />
                      {errors.start_date && <div className='invalid-feedback'>{errors.start_date}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='end_date' className='col-form-label'>
                        End Date
                      </label>
                      <input type='date' className={`form-control ${errors.end_date ? "is-invalid" : ""}`} id='end_date' name='end_date' value={formData.end_date} onChange={handleChange} />
                      {errors.end_date && <div className='invalid-feedback'>{errors.end_date}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='total_uses_allowed' className='col-form-label'>
                        Total Uses Allowed
                      </label>
                      <input
                        type='number'
                        className={`form-control ${errors.total_uses_allowed ? "is-invalid" : ""}`}
                        id='total_uses_allowed'
                        name='total_uses_allowed'
                        value={formData.total_uses_allowed}
                        onChange={handleChange}
                      />
                      {errors.total_uses_allowed && <div className='invalid-feedback'>{errors.total_uses_allowed}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='uses_per_customer_allowed' className='col-form-label'>
                        Uses Per Customer Allowed
                      </label>
                      <input
                        type='number'
                        className={`form-control ${errors.uses_per_customer_allowed ? "is-invalid" : ""}`}
                        id='uses_per_customer_allowed'
                        name='uses_per_customer_allowed'
                        value={formData.uses_per_customer_allowed}
                        onChange={handleChange}
                      />
                      {errors.uses_per_customer_allowed && <div className='invalid-feedback'>{errors.uses_per_customer_allowed}</div>}
                    </div>
                    <div className='d-flex align-items-center gap-3'>
                         <label>
                        <input type='checkbox' name='isActive' checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                      </label>
                      Active
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
