import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { AmenityMultiCheck } from "../amenity_multi_check/Component.js";
import Select from "react-select";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [amenities, setAmenities] = useState([]);
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [pricingTypes, setPricingTypes] = useState([]);

  const [formData, setFormData] = useState(initialFormData);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectValues, setSelectValues] = useState({
    property_id: "",
    room_type: "",
  });

  useEffect(() => {
    const property = properties?.find((item) => item._id === formData.property_id) || "";
    setSelectValues((prev) => ({
      ...prev,
      property_id: { label: property.title, value: property._id },
    }));
  }, [formData.property_id, properties]);

  useEffect(() => {
    const selectedType = roomTypes?.find((item) => item._id === formData.room_type);
    setSelectValues((prev) => ({
      ...prev,
      room_type: selectedType ? { label: selectedType.name, value: selectedType._id } : "",
    }));

    if (formData.room_type) {
      fetchMastersRoomTypeData(formData.room_type);
    }
  }, [formData.room_type, roomTypes]);

  // to fetch masters data
  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        urlName: hotelId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.apartment_amenity_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const propertiesResponse = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const roomTypesResponse = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const pricingTypesResponse = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok || !propertiesResponse.ok || !roomTypesResponse.ok || !pricingTypesResponse) {
        throw new Error("Network response was not ok");
      }

      const amenitiesData = await response.json();
      const propertiesData = await propertiesResponse.json();
      const roomTypesData = await roomTypesResponse.json();
      const pricingTypesData = await pricingTypesResponse.json();

      setAmenities(amenitiesData.Data);
      setProperties(propertiesData.Data);
      setRoomTypes(roomTypesData.Data);
      setPricingTypes(pricingTypesData.Data);

      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // for edit modal ---------------------------------------------------------------------------------
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      amenities: pricingTypes?.map((item) => ({
        category: item._id,
        amenities: [],
      })),
    }));
  }, [pricingTypes]);

  const fetchMastersRoomTypeData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(client_prefix);
      }

      const data = await response.json();

      console.log("masters room type data", data);

      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setFormData({
          ...formData,
          suffix: response_data.suffix || "",
          prefix: response_data.prefix || "",
          increment_type: response_data.increment_type || "",
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  // ----------------------------------------------------------------

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSelectAmenities = (category, value) => {
    console.log(category, value);

    setFormData((prev) => ({
      ...prev,
      amenities: prev.amenities.map((item) => (item.category === category ? { ...item, amenities: value } : item)),
    }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.property_id.trim()) {
      errors.property_id = "Property is required";
      isValid = false;
    }

    if (!formData.room_type.trim()) {
      errors.room_type = "Room Type is required";
      isValid = false;
    }

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (formData.base_price == 0) {
      errors.base_price = "Base Price cannot be zero";
      isValid = false;
    }

    if (formData.num_of_apartments == 0) {
      errors.num_of_apartments = "Number of rooms cannot be zero";
      isValid = false;
    }

    if (!formData.prefix.trim()) {
      errors.prefix = "Prefix is required";
      isValid = false;
    }
    if (!formData.suffix.trim()) {
      errors.suffix = "Suffix is required";
      isValid = false;
    }
    if (!formData.increment_type.trim()) {
      errors.increment_type = "Type is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.room_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({ urlName: hotelId, ...formData }),
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.Message || "Failed to add room.");
        }

        const responseData = await response.json();

        if (!responseData.Status) {
          toast.error(responseData.Message || "Failed to add room.");
          return;
        }

        if (names.length > 0) {
          const roomNamesData = names.map((item) => ({
            property_id: formData.property_id,
            room_id: responseData.data[0]._id,
            name: item,
            urlName: hotelId,
          }));

          const roomNamesResponse = await fetch(url + clientpoints.room_name_add, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify(roomNamesData),
          });

          if (!roomNamesResponse.ok) {
            const errorResponse = await roomNamesResponse.json();
            throw new Error(errorResponse.Message || "Failed to add room names.");
          }
        } else {
          toast.error("No room names found!");
        }

        toast.success(responseData.Message || "Room added successfully!");
        setFormData(initialFormData);
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details correctly!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  const [names, setNames] = useState([]);

  const generateNames = ({ num_of_apartments, prefix, suffix, increment_type }) => {
    return Array.from({ length: Number(num_of_apartments) || 1 }, (_, index) => {
      let incrementalValue;

      if (increment_type === "Numeric") {
        incrementalValue = index + 1;
      } else if (increment_type === "Alphabet") {
        const place = index + 1;
        if (place <= 26) {
          incrementalValue = String.fromCharCode(64 + place);
        } else {
          const firstLetter = String.fromCharCode(64 + Math.floor((place - 1) / 26));
          const secondLetter = String.fromCharCode(64 + ((place - 1) % 26) + 1);
          incrementalValue = firstLetter + secondLetter;
        }
      }

      return `${prefix}${incrementalValue}${suffix}`;
    });
  };

  useEffect(() => {
    if (formData.room_type) {
      setNames(generateNames(formData));
    }
  }, [formData.room_type, formData.prefix, formData.suffix, formData.increment_type]);

  // const handleBlur = () => {
  //   setNames(generateNames(formData));
  // };

  const handleBlur = () => {
    setNames((prev) => {
      const newLength = Number(formData.num_of_apartments) || 1;
      const currentNames = [...prev];

      if (newLength > currentNames.length) {
        // Add new names for additional fields
        const additionalNames = Array.from({ length: newLength - currentNames.length }, (_, index) => {
          let incrementalValue;
          const baseIndex = currentNames.length + index;

          if (formData.increment_type === "Numeric") {
            incrementalValue = baseIndex + 1;
          } else if (formData.increment_type === "Alphabet") {
            const place = baseIndex + 1;
            if (place <= 26) {
              incrementalValue = String.fromCharCode(64 + place);
            } else {
              const firstLetter = String.fromCharCode(64 + Math.floor((place - 1) / 26));
              const secondLetter = String.fromCharCode(64 + ((place - 1) % 26) + 1);
              incrementalValue = firstLetter + secondLetter;
            }
          }

          return `${formData.prefix}${incrementalValue}${formData.suffix}`;
        });
        return [...currentNames, ...additionalNames];
      } else if (newLength < currentNames.length) {
        // Remove extra names from the end
        return currentNames.slice(0, newLength);
      }
      return currentNames; // If length is the same, keep as is
    });
  };

  const handleNameChange = (e, index) => {
    const { value } = e.target;
    setNames((prev) => prev.map((item, i) => (i === index ? value : item)));
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>

              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body" style={{ maxHeight: "70vh", overflowY: "scroll", padding: "0 15px" }}>
                    <div className="">
                      {/* ------------------------------------------------------------------- */}

                      {/* --------------------------------------------------------------------- */}
                      <div className="row">
                        {/* PROPERTY TYPE-------------------------------- */}
                        <div className="col-md-12">
                          <label htmlFor="property_id" className="col-form-label">
                            Property
                          </label>
                          <Select
                            required
                            options={properties?.map((property) => ({ label: `${property.title}`, value: property._id }))}
                            value={selectValues.property_id}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                property_id: val.value,
                              }))
                            }
                            placeholder="Select Property..."
                          />
                          {errors.property_id && <div className="invalid-feedback">{errors.property_id}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="room_type" className="col-form-label">
                            Room Type
                          </label>
                          <Select
                            required
                            options={roomTypes?.map((type) => ({ label: type.name, value: type._id }))}
                            value={selectValues.room_type}
                            onChange={(val) =>
                              setFormData((prev) => ({
                                ...prev,
                                room_type: val.value,
                              }))
                            }
                            placeholder="Select Room Type..."
                          />
                          {errors.room_type && <div className="invalid-feedback">{errors.room_type}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="name" className="col-form-label">
                            Name
                          </label>
                          <input type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`} name="name" id="name" value={formData.name} onChange={handleChange} />
                          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="description" className="col-form-label">
                            Description
                          </label>
                          <textarea
                            rows={4}
                            type="text"
                            className={`form-control ${errors.description ? "is-invalid" : ""}`}
                            name="description"
                            id="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                          {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="rating" className="col-form-label">
                            Extra Charges Type
                          </label>
                          <select id="rating" className={`form-select`} value={formData.rating} onChange={(e) => setFormData((prev) => ({ ...prev, rating: e.target.value }))}>
                            <option value="Fixed">Fixed</option>
                            <option value="Per day">Per day</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="base_price" className="col-form-label">
                            Base Price
                          </label>
                          <input
                            type="number"
                            className={`form-control ${errors.base_price ? "is-invalid" : ""}`}
                            id="base_price"
                            name="base_price"
                            value={formData.base_price}
                            onChange={handleChange}
                          />
                          {errors.base_price && <div className="invalid-feedback">{errors.base_price}</div>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="cleaning_fee" className="col-form-label">
                            Cleaning Fee
                          </label>
                          <input type="number" className={`form-control `} id="cleaning_fee" name="cleaning_fee" value={formData.cleaning_fee} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="utility_charges" className="col-form-label">
                            Utility Charges
                          </label>
                          <input type="number" className={`form-control `} id="utility_charges" name="utility_charges" value={formData.utility_charges} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="electric_charges" className="col-form-label">
                            Electric Charges
                          </label>
                          <input type="number" className={`form-control `} id="electric_charges" name="electric_charges" value={formData.electric_charges} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="area_measurement" className="col-form-label">
                            Area measurement
                          </label>
                          <input type="number" className={`form-control `} id="area_measurement" name="area_measurement" value={formData.area_measurement} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_bedrooms" className="col-form-label">
                            Number of Bedrooms
                          </label>
                          <input type="number" className={`form-control `} id="num_of_bedrooms" name="num_of_bedrooms" value={formData.num_of_bedrooms} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_beds" className="col-form-label">
                            Number of Beds
                          </label>
                          <input type="number" className={`form-control `} id="num_of_beds" name="num_of_beds" value={formData.num_of_beds} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_bathrooms" className="col-form-label">
                            Number of Bathrooms
                          </label>
                          <input type="number" className={`form-control `} id="num_of_bathrooms" name="num_of_bathrooms" value={formData.num_of_bathrooms} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_livingRooms" className="col-form-label">
                            Number of Living Rooms
                          </label>
                          <input type="number" className={`form-control `} id="num_of_livingRooms" name="num_of_livingRooms" value={formData.num_of_livingRooms} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_adults" className="col-form-label">
                            Number of Adults
                          </label>
                          <input type="number" className={`form-control `} id="num_of_adults" name="num_of_adults" value={formData.num_of_adults} onChange={handleChange} />
                        </div>{" "}
                        <div className="col-md-6">
                          <label htmlFor="num_of_kids" className="col-form-label">
                            Number of Kids
                          </label>
                          <input type="number" className={`form-control `} id="num_of_kids" name="num_of_kids" value={formData.num_of_kids} onChange={handleChange} />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                         <label>
                            <input type="checkbox" name="isActive" checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                          </label>
                          Active
                        </div>{" "}
                        <div className="col-md-12 mb-2">
                          <label htmlFor="notes" className="col-form-label">
                            Notes
                          </label>
                          <textarea rows={4} type="text" className={`form-control `} name="notes" id="notes" value={formData.notes} onChange={handleChange} />
                        </div>
                        <div className="priceAmenitiesContainer">
                          {formData?.amenities?.map((item, i) => (
                            <div key={i} className="md:col-span-4 col-span-12">
                              <span className="font-[600] text-gray-400">{pricingTypes.find((am) => am._id === item.category)?.name} Amenities</span>
                              <AmenityMultiCheck options={amenities} selected={item.amenities} category={item.category} onCheck={handleSelectAmenities} />
                            </div>
                          ))}
                        </div>
                        <hr />
                        <h4 className="center">Rooms Mapping</h4>
                        <div className="col-md-6">
                          <label htmlFor="num_of_apartments" className="col-form-label">
                            Number of rooms
                          </label>
                          <input
                            min={1}
                            type="number"
                            className={`form-control ${errors.num_of_apartments ? "is-invalid" : ""}`}
                            id="num_of_apartments"
                            name="num_of_apartments"
                            value={formData.num_of_apartments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          {errors.num_of_apartments && <div className="invalid-feedback">{errors.num_of_apartments}</div>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="prefix" className="col-form-label">
                            Room Number Prefix
                          </label>
                          <input
                            placeholder="4"
                            type="text"
                            className={`form-control ${errors.prefix ? "is-invalid" : ""}`}
                            id="prefix"
                            name="prefix"
                            value={formData.prefix}
                            onChange={handleChange}
                          />
                          {errors.prefix && <div className="invalid-feedback">{errors.prefix}</div>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="suffix" className="col-form-label">
                            Room Number Suffix
                          </label>
                          <input
                            placeholder="A"
                            type="text"
                            className={`form-control ${errors.suffix ? "is-invalid" : ""}`}
                            id="suffix"
                            name="suffix"
                            value={formData.suffix}
                            onChange={handleChange}
                          />
                          {errors.suffix && <div className="invalid-feedback">{errors.suffix}</div>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="increment_type" className="col-form-label">
                            Type
                          </label>
                          <select
                            id="increment_type"
                            className={`form-select ${errors.increment_type ? "is-invalid" : ""}`}
                            value={formData.increment_type}
                            onChange={(e) => setFormData((prev) => ({ ...prev, increment_type: e.target.value }))}
                          >
                            <option value="Numeric">Numeric</option>
                            <option value="Alphabet">Alphabet</option>
                          </select>
                          {errors.increment_type && <div className="invalid-feedback">{errors.increment_type}</div>}
                        </div>
                        <h5 className="mt-2">Room names</h5>
                        <div className="row">
                          {names.map((item, index) => (
                            <div key={index} className="col-md-3 mb-2">
                              <input type="text" className="form-control" value={item} onChange={(e) => handleNameChange(e, index)} />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const initialFormData = {
  property_id: "",
  room_type: "",
  name: "",
  description: "",
  extra_charges_type: "Fixed",
  base_price: "",
  cleaning_fee: "",
  utility_charges: "",
  electric_charges: "",
  area_measurement: "",
  isActive: true,
  num_of_apartments: 1,
  prefix: "",
  suffix: "",
  increment_type: "Numeric",
  num_of_bedrooms: "",
  num_of_beds: "",
  num_of_bathrooms: "",
  num_of_livingRooms: "",
  num_of_adults: "",
  num_of_kids: "",
  notes: "",
  amenities: [],
  images: [],
};
