import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "",

    hotel_dashboard: { add: false, edit: false, delete: false, view: false },

    property_type: { add: false, edit: false, delete: false, view: false },
    room_type: { add: false, edit: false, delete: false, view: false },
    pricing_type: { add: false, edit: false, delete: false, view: false },
    pricing_type_variant: { add: false, edit: false, delete: false, view: false },
    property_amenity: { add: false, edit: false, delete: false, view: false },
    apartment_amenity: { add: false, edit: false, delete: false, view: false },
    faq: { add: false, edit: false, delete: false, view: false },

    area: { add: false, edit: false, delete: false, view: false },
    property: { add: false, edit: false, delete: false, view: false },
    room: { add: false, edit: false, delete: false, view: false },

    room_price_formula: { add: false, edit: false, delete: false, view: false },
    room_inventory_price: { add: false, edit: false, delete: false, view: false },

    role: { add: false, edit: false, delete: false, view: false },
    team: { add: false, edit: false, delete: false, view: false },

    user: { add: false, edit: false, delete: false, view: false },

    review: { add: false, edit: false, delete: false, view: false },

    booking: { add: false, edit: false, delete: false, view: false },
    feedback: { add: true, edit: true, delete: true, view: true },

    coupon: { add: false, edit: false, delete: false, view: false },

    visitor: { add: false, edit: false, delete: false, view: false },

    enquiry: { add: false, edit: false, delete: false, view: false },

    quotation: { add: false, edit: false, delete: false, view: false },

    expense: { add: false, edit: false, delete: false, view: false },

    ticket: { add: false, edit: false, delete: false, view: false },
    client_ticket: { add: false, edit: false, delete: false, view: false },

    hotel: { add: false, edit: false, delete: false, view: false },
    subscription: { add: false, edit: false, delete: false, view: false },

    log_view: { add: false, edit: false, delete: false, view: false },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = (role, action) => {
    setFormData({
      ...formData,
      [role]: {
        ...formData[role],
        [action]: !formData[role][action],
      },
    });
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let roleId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && roleId) {
      setLoading(true);
      fetchData(roleId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, roleId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.role_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch role data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          ...response_data,
        });
      }
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.role_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              ...formData,
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `View ${modalName}` : `Edit ${modalName}`}{" "}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body' style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                    <div className='row'>
                      <label className='col-md-6 mb-3'>
                        Role Name
                        <input disabled={mode === "view"} type='text' name='name' value={formData.name} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                      </label>

                      {formData && typeof formData === "object" && (
                        <div className='flex flex-col items-center'>
                          <table className='table table-bordered'>
                            <thead>
                              <tr className='bg-gray-200'>
                                <th className='border border-gray-400'>Name</th>
                                <th className='border border-gray-400'>Add</th>
                                <th className='border border-gray-400'>Edit</th>
                                <th className='border border-gray-400'>View</th>
                                <th className='border border-gray-400'>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(formData)
                                .filter(
                                  ([roleName, roleData]) =>
                                    roleName === "hotel_dashboard" ||
                                    roleName === "property_type" ||
                                    roleName === "room_type" ||
                                    roleName === "pricing_type" ||
                                    roleName === "pricing_type_variant" ||
                                    roleName === "property_amenity" ||
                                    roleName === "apartment_amenity" ||
                                    roleName === "faq" ||
                                    roleName === "area" ||
                                    roleName === "property" ||
                                    roleName === "room" ||
                                    roleName === "room_price_formula" ||
                                    roleName === "room_inventory_price" ||
                                    roleName === "role" ||
                                    roleName === "team" ||
                                    roleName === "user" ||
                                    roleName === "review" ||
                                    roleName === "booking" ||
                                    roleName === "feedback" ||
                                    roleName === "coupon" ||
                                    roleName === "visitor" ||
                                    roleName === "enquiry" ||
                                    roleName === "quotation" ||
                                    roleName === "expense" ||
                                    roleName === "ticket" ||
                                    roleName === "client_ticket" ||
                                    roleName === "hotel" ||
                                    roleName === "subscription" ||
                                    roleName === "log_view"
                                )
                                .map(([roleName, roleData]) => {
                                  const formatRoleName = (name) => {
                                    return name
                                      .split("_")
                                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                      .join(" ");
                                  };
                                  return (
                                    <tr key={roleName}>
                                      <td className='border border-gray-400'>{formatRoleName(roleName)}</td>
                                      <td className='border border-gray-400'>
                                        <input disabled={mode === "view"} type='checkbox' checked={roleData.add} onChange={() => handleCheckboxChange(roleName, "add")} />
                                      </td>
                                      <td className='border border-gray-400'>
                                        <input disabled={mode === "view"} type='checkbox' checked={roleData.edit} onChange={() => handleCheckboxChange(roleName, "edit")} />
                                      </td>
                                      <td className='border border-gray-400'>
                                        <input disabled={mode === "view"} type='checkbox' checked={roleData.view} onChange={() => handleCheckboxChange(roleName, "view")} />
                                      </td>
                                      <td className='border border-gray-400'>
                                        <input disabled={mode === "view"} type='checkbox' checked={roleData.delete} onChange={() => handleCheckboxChange(roleName, "delete")} />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    {mode !== "view" && (
                      <>
                        <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                          Close
                        </button>
                        {/* <button type="submit" className="btn btn-primary">Update</button> */}
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
