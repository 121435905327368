import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import SearchableSelectComponentProperty from "../property_select/Component.js";
import SearchableSelectComponentUser from "../user_select/Component.js";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [propertyName, setPropertyName] = useState("");
  const [userName, setUserName] = useState("");

  const initialFormData = {
    rating: 1,
    review: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [propertyList, setPropertyList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  const [searchValueUser, setSearchValueUser] = useState("");
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [showDropdownUser, setShowDropdownUser] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!propertyName.trim()) {
      errors.property = "Property is required";
      isValid = false;
    }
    if (!userName.trim()) {
      errors.user = "User is required";
      isValid = false;
    }

    if (formData.rating < 1) {
      errors.rating = "Rating cannot be less than 1";
      isValid = false;
    }

    if (formData.rating > 5) {
      errors.rating = "Rating cannot be more than 5";
      isValid = false;
    }

    if (!formData.review.trim()) {
      errors.review = "Review is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.review_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            property_id: propertyName,
            user_id: userName,
            rating: formData.rating,
            review: formData.review,
          }),
        });
        setPropertyName("");
        setUserName("");
        setFormData(initialFormData);
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPropertyName("");
    setUserName("");
    setFormData(initialFormData);
  };
  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div>
                    <label htmlFor='user' className='col-form-label'>
                      Select User
                    </label>
                    <SearchableSelectComponentUser
                      setUserName={setUserName}
                      searchValueProps={{ searchValueUser, setSearchValueUser }}
                      suggestionsProps={{ suggestionsUser, setSuggestionsUser }}
                      showDropdownProps={{ showDropdownUser, setShowDropdownUser }}
                      form={false}
                    />
                    {errors.user && <div className='invalid-feedback'>{errors.user}</div>}
                  </div>
                  <div>
                    <label htmlFor='property' className='col-form-label'>
                      Select Property
                    </label>
                    <SearchableSelectComponentProperty
                      setPropertyName={setPropertyName}
                      searchValueProps={{ searchValue, setSearchValue }}
                      suggestionsProps={{ suggestions, setSuggestions }}
                      showDropdownProps={{ showDropdown, setShowDropdown }}
                      form={false}
                    />
                    {errors.property && <div className='invalid-feedback'>{errors.property}</div>}
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <label htmlFor='rating' className='col-form-label'>
                        Rating (out of 5)
                      </label>
                      <input type='number' className={`form-control ${errors.rating ? "is-invalid" : ""}`} id='rating' name='rating' value={formData.rating} onChange={handleChange} />
                      {errors.rating && <div className='invalid-feedback'>{errors.rating}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='review' className='col-form-label'>
                        Review
                      </label>
                      <textarea className={`form-control ${errors.review ? "is-invalid" : ""}`} id='review' name='review' value={formData.review} onChange={handleChange} />
                      {errors.review && <div className='invalid-feedback'>{errors.review}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
