import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";

const formulaObj = { value: "", type: "percentage", operator: "+" };

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    color: "#000000",
    description: "",
    isActive: false,
  });

  const [additionalFields, setAdditionalFields] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    if (additionalFields.length < 1) {
      errors.variant = "Add atleast one variant!";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  console.log("errorsss", errors);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("form dataaa", formData);
    console.log("variantssss", additionalFields);

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.pricing_type_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            name: formData.name,
            isActive: formData.isActive,
            color: formData.color,
            description: formData.description,
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          toast.success(responseData.Message);

          if (additionalFields.length > 0) {
            const newPricingTypeId = responseData.data[0]._id;

            const variantDataToAdd = additionalFields.map((item) => ({ ...item, pricing_type_id: newPricingTypeId, urlName: hotelId }));

            const response1 = await fetch(url + clientpoints.pricing_type_variant_add, {
              method: "POST",
              headers: pageHeaders,
              credentials: "include",
              body: JSON.stringify(variantDataToAdd),
            });

            if (!response1.ok) {
              throw new Error("Failed to add variants");
            }
          }

          setFormData({
            name: "",
            isActive: false,
            color: "#000000",
            description: "",
          });
          setAdditionalFields([]);
          setIsModalOpen(false);
          onRefresh();
        } else {
          toast.error(responseData.Message);
          setIsModalOpen(false);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setFormData({
      name: "",
      isActive: false,
      color: "#000000",
      description: "",
    });
  };

  const addField = () => {
    setAdditionalFields([...additionalFields, { name: "", description: "", formula: { ...formulaObj } }]);
  };

  const removeField = (index) => {
    const updatedFields = additionalFields.filter((_, i) => i !== index);
    setAdditionalFields(updatedFields);
  };

  const handleChangeDynamicFields = (event, index, key) => {
    const value = event.target.value;
    const updatedFields = [...additionalFields];
    updatedFields[index][key] = value;
    setAdditionalFields(updatedFields);
  };

  const handleChangeInput = (e, index) => {
    const [field, subField] = e.target.name.split(".");
    const value = e.target.type === "number" ? +e.target.value : e.target.value;

    const updatedFields = [...additionalFields];
    updatedFields[index].formula[subField] = value;
    setAdditionalFields(updatedFields);
  };

  const multiLevelInputChange = (prev, remNames, value) => {
    const [name1, ...names] = remNames;
    return {
      ...prev,
      [name1]: names?.length > 0 ? multiLevelInputChange(prev[name1], names, value) : value,
    };
  };

  console.log("additinal fieldssss-----", additionalFields);

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body' style={{ maxHeight: "70vh", overflowY: "auto" }}>
                  <div className='row'>
                    <div className='col-md-10'>
                      <label htmlFor='name' className='col-form-label'>
                        Name
                      </label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>

                    <div className='col-md-2'>
                      <label htmlFor='name' className='col-form-label'>
                        Color
                      </label>
                      <input type='color' className={`form-control `} id='color' name='color' value={formData.color} onChange={handleChange} />
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='name' className='col-form-label'>
                        Description
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.description ? "is-invalid" : ""}`}
                        id='description'
                        name='description'
                        value={formData.description}
                        onChange={handleChange}
                      />
                      {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                    </div>

                    {/* <div className='d-flex align-items-center gap-3'>
                      Active
                      <label>
                        <input type='checkbox' name='isActive' checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                      </label>
                    </div> */}

                    <hr /> 

                    {additionalFields.map((field, index) => (
                      <div key={index} className='col-md-12 mb-3' id="remove_button_here">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <label htmlFor={`additionalField${index}`}>Pricing Type Variant {index + 1}</label>
                          <button type='button' className='btn btn-danger' id="remove_button_here" onClick={() => removeField(index)}>
                            Remove
                          </button>
                        </div>
                        <div className='input-group'>
                          <input
                            placeholder='Name'
                            type='text'
                            className='form-control'
                            id={`additionalFieldName${index}`}
                            name={`additionalFieldName${index}`}
                            value={field.name}
                            onChange={(event) => handleChangeDynamicFields(event, index, "name")}
                          />
                        </div>
                        <textarea
                          placeholder='Description'
                          rows={3}
                          className='form-control'
                          id={`additionalFieldDescription${index}`}
                          name={`additionalFieldDescription${index}`}
                          value={field.description}
                          onChange={(event) => handleChangeDynamicFields(event, index, "description")}
                        />
                        <FormulaBox name={`additionalFieldFormula${index}`} value={field.formula} onChange={(e) => handleChangeInput(e, index)} />
                      </div>
                    ))}

                    <div className='col-md-12'>
                      <button type='button' className='btn btn-success' onClick={addField}>
                        Add Variant
                      </button>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const FormulaBox = ({ name = "", value = {}, onChange = () => {} }) => {
  return (
    <>
      <div className='row'>
        <label className='fw-semibold'>Default Formula</label>
        
      <div className='col-sm-4'>
        <NormalInput
          placeholder='Enter value...'
          type='number'
          name={name + ".value"}
          value={value.value}
          onChange={onChange}
          // min=''
          />
        </div>
      <div className='col-sm-4'>
        <NormalSelect name={name + ".type"} value={value.type} onChange={onChange} options={typeOptions} optionType='object' optionValue='value' />
      </div>
      <div className='col-sm-4'>
        <NormalSelect name={name + ".operator"} value={value.operator} onChange={onChange} options={operatorOptions} optionType='object' optionValue='value' />
      </div>
          
      </div>
    </>
  );
};

export const NormalSelect = ({ label = "", name = "", disabled = false, value = "", onChange, options = [], optionType = "string", optionLabel = "name", optionValue = "_id" }) => {
  return (
    <div className=''>
      {label && <label className='form-label fw-semibold text-muted'>{label}</label>}
      <select name={name} disabled={disabled} value={value} onChange={onChange} className='form-select'>
        {options.map((item, i) => (
          <option key={i} value={optionType === "string" ? item : item[optionValue]}>
            {optionType === "string" ? item : item[optionLabel]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const NormalInput = ({ label, type = "text", name = "", disabled = false, readOnly = false, value = "", onChange, min = "", max = "", inputStyles = {}, placeholder = "" }) => {
  return (
    <div className=''>
      {label && <label className='form-label fw-semibold text-muted'>{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        className={`form-control ${disabled ? "bg-light text-muted" : ""} ${readOnly ? "bg-light" : ""}`}
        style={inputStyles}
      />
    </div>
  );
};

const typeOptions = [
  {
    name: "Percentage",
    value: "percentage",
  },
  {
    name: "Fixed",
    value: "fixed",
  },
];
const operatorOptions = [
  {
    name: "Plus (+)",
    value: "+",
  },
  {
    name: "Minus (-)",
    value: "-",
  },
];
