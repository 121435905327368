import React, { useState } from "react";
import { Link } from "react-router-dom";
import { endpoints, url } from "../../../lib/lib.js";
import toast, { Toaster } from "react-hot-toast";
import "./component.css";

const Component = () => {
  const notify = () => toast.success("Message Sent Successfully.");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    if (phoneError) {
      setPhoneError(""); // Clear the error message
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inputValue = phoneNumber.trim();

    if (inputValue === "") {
      setPhoneError("Phone number is required.");
      return;
    } else if (!/^\d{10}$/.test(inputValue)) {
      setPhoneError("Phone number must be 10 digits long.");
      return;
    }
    setPhoneNumber("");
    setPhoneError("");
    notify();
    try {
      const response = await fetch(url + endpoints.call_back, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: inputValue }), // Send only phone number in the request body
      });

      const data = await response.json();

      if (data.Status) {
      } else {
        throw new Error(data.Message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='section-footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-1'></div>
            <div className='col-lg-3'>
              <img src='img/staystation.png' className='img-fluid img-footerlogo' />
              <br />
              <br />
              <div className='footer-flx'>
                <div className='foot-flx1'>
                  <i className='ri-phone-line call-icn'></i>
                </div>
                <div className='foot-flx1'>
                  <p className='call-txt'>(+91) - 8448332454</p>
                </div>
              </div>
              <div className='footer-flx'>
                <div className='foot-flx1'>
                  <i className='ri-mail-send-line call-icn'></i>
                </div>
                <div className='foot-flx1'>
                  <p className='call-txt'>staystation@encrobytes.com</p>
                </div>
              </div>
              <div className='footer-flx'>
                <div className='foot-flx1'>
                  <i className='ri-map-pin-line call-icn'></i>
                </div>
                <div className='foot-flx1'>
                  <p className='call-txthotel'>&Work Coworking, Plot No. 5B, Sector 15A Neelam Chowk Ajronda Metro Near Crown Plaza, Faridabad, Haryana 121007</p>
                </div>
              </div>
            </div>
            <div className='col-lg-2'></div>
            <div className='col-lg-6'>
              <div className='row'>
                <div className='col-lg-5 col-md-6 col-sm-6'>
                  <p class='footer_heading'>Links</p>
                  {/* <p class="footer_content">Home</p> */}
                  <p class='footer_content1'>
                    <a href='#features' className='linkcolor'>
                      Features
                    </a>
                  </p>
                  <p class='footer_content1'>
                    <a href='#pricing' className='linkcolor'>
                      Pricing
                    </a>
                  </p>
                  <p class='footer_content1'>
                    <Link to='/login' className='linkcolor'>
                      Login
                    </Link>
                  </p>
                  <p class='footer_content1'>
                    <a href='#contact' className='linkcolor'>
                      Get Free Trial
                    </a>
                  </p>
                  <p class='footer_content1'>
                    <Link to='/privacy' className='linkcolor'>
                      Privacy Policy
                    </Link>
                  </p>
                  <p class='footer_content1'>
                    <Link to='/terms' className='linkcolor'>
                      Terms & Conditions
                    </Link>
                  </p>
                  {/* <p class="footer_content1">AI &amp; ML</p> */}
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                  <p class='footer_heading'>Request A Call Back</p>
                  <div class='container2'>
                    <form onSubmit={handleSubmit}>
                      <input type='text' className='input-field2' placeholder='Enter Phone Number' value={phoneNumber} onChange={handleInputChange} />
                      <button type='submit' className='submit-button2'>
                        Submit
                      </button>
                    </form>
                    {phoneError && (
                      <span className='error' style={{ color: "red", position: "absolute" }}>
                        {phoneError}
                      </span>
                    )}
                  </div>
                  {phoneError.phoneNumber && <span className='error-message'>{phoneError.phoneNumber}</span>}
                  <p className={`cont-txt ${phoneError ? "with-error" : ""}`}>
                    Try out our product for free, we've got you covered! Contact us today and we'll arrange a call back to get you started with a complimentary trial.
                  </p>
                </div>
                <div className='col-lg-1 col-md-6 col-sm-6'></div>
                {/* <div className="col-lg-4 col-md-6 col-sm-6">
          <p class="footer_heading">Tech Stack</p>
          <p class="footer_content">C, C++ &amp; DSA</p>
          <p class="footer_content1">Web Frontend</p>
          <p class="footer_content1">PHP &amp; MYSQL</p>
          <p class="footer_content1">JAVA &amp; MYSQL</p>
          <p class="footer_content1">MERN STACK</p>
          <p class="footer_content1">React Native</p>
          </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className='footer1-last'>
          <div className='foot1-flx1'></div>
          <div className='foot1-flx2'>
            <img src='https://encrobytes.com/img/logo-white.png' className='img-fluid last-logo' />
            <p className='copyright'>
              Copyright © 2024{" "}
              <a href='https://encrobytes.com/' target='blank' className='copyrightlink'>
                Encrobytes
              </a>
              . All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
