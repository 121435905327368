import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { MultiCheck } from "../../../ui/multi_check/Component.js";
import Select from "react-select";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh, data = "", view }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [amenities, setAmenities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingError, setIsSubmittingError] = useState(false);

  const [selectValues, setSelectValues] = useState({
    area: "",
    property_type: "",
  });

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  let propertyId = data;

  useEffect(() => {
    const area = areas?.find((item) => item._id === formData.area) || "";
    setSelectValues((prev) => ({
      ...prev,
      area: { label: area.area_name, value: area._id },
    }));
  }, [formData.area, areas]);

  useEffect(() => {
    const selectedType = propertyTypes?.find((item) => item._id === formData.property_type);
    setSelectValues((prev) => ({
      ...prev,
      property_type: selectedType ? { label: selectedType.name, value: selectedType._id } : "",
    }));
  }, [formData.property_type, propertyTypes]);

  console.log("form dataaa", formData);

  // to fetch masters data
  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        urlName: hotelId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.property_amenity_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const areasResponse = await fetch(url + clientpoints.area_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const propertyTypesResponse = await fetch(url + clientpoints.property_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok || !areasResponse.ok || !propertyTypesResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const amenitiesData = await response.json();
      const areasData = await areasResponse.json();
      const propertyTypesData = await propertyTypesResponse.json();

      setAmenities(amenitiesData.Data);
      setAreas(areasData.Data);
      setPropertyTypes(propertyTypesData.Data);

      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // for edit modal ---------------------------------------------------------------------------------
  useEffect(() => {
    if (isModalOpen && propertyId && (view === "edit" || view === "view")) {
      setLoading(true);
      fetchPropertyData(propertyId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, propertyId, view]);

  const fetchPropertyData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        console.log("property dataaaa", response_data);

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          ...response_data,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  // ----------------------------------------------------------------

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleNextStep = () => {
    if (validateForm()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSetValue = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeObjectInput = (e, type) => {
    setFormData((prev) => ({
      ...prev,
      [type]: { ...prev[type], [e.target.name]: e.target.value },
    }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (activeStep === 1) {
      if (!formData.billing_detail.legal_company.trim()) {
        errors.legal_company = "Legal Company is required";
        isValid = false;
      }

      if (!formData.billing_detail.billing_address.trim()) {
        errors.billing_address = "Billing Address is required";
        isValid = false;
      }

      if (!formData.bank_detail.account_name.trim()) {
        errors.account_name = "Account Name is required";
        isValid = false;
      }
      if (!formData.bank_detail.account_number.trim()) {
        errors.account_number = "Account Number is required";
        isValid = false;
      }
      if (!formData.bank_detail.ifsc.trim()) {
        errors.ifc = "IFSC is required";
        isValid = false;
      }
      if (!formData.bank_detail.bank_name.trim()) {
        errors.bank_name = "Bank Name is required";
        isValid = false;
      }
      if (!formData.mail_detail.smtp_email.trim()) {
        errors.smtp_email = "SMTP email is required";
        isValid = false;
      }
      if (!formData.mail_detail.smtp_password.trim()) {
        errors.smtp_password = "SMTP password is required";
        isValid = false;
      }
    }

    if (activeStep === 2) {
      if (!formData.title.trim()) {
        errors.title = "Title is required";
        isValid = false;
      }

      if (!formData.slug.trim()) {
        errors.slug = "Slug is required";
        isValid = false;
      }

      if (!formData.contact_email.trim()) {
        errors.contact_email = "Email is required";
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(formData.contact_email)) {
        errors.contact_email = "Invalid email address";
        isValid = false;
      }
      if (!formData.contact_number.trim()) {
        errors.contact_number = "Phone is required";
        isValid = false;
      } else if (!/^\d{10}$/.test(formData.contact_number)) {
        errors.contact_number = "Phone number must be 10 digits";
        isValid = false;
      }
    }

    if (activeStep === 3) {
      if (!formData.area.trim()) {
        errors.area = "Area is required";
        isValid = false;
      }
    }

    setErrors(errors);
    return isValid;
  };

  const submitFormData = async () => {
    let apiEndpoint = "";
    let filters = {};

    let requestBody = {};

    if (view === "add") {
      apiEndpoint = clientpoints.property_add;
      requestBody = { urlName: hotelId, ...formData };
    } else if (view === "edit") {
      apiEndpoint = clientpoints.property_edit;
      filters = { _id: propertyId };
      requestBody = {
        filters: filters,
        urlName: hotelId,
        data: { ...formData },
      };
    }

    try {
      const response = await fetch(url + apiEndpoint, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.Status) {
        toast.success(responseData.Message);
        setFormData(initialFormData);
      } else {
        toast.error(responseData.Message);
      }

      onRefresh();
      setActiveStep(1);
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (validateForm()) {
      try {
        const slugResponse = await fetch(url + clientpoints.property_view, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({ urlName: hotelId, filters: { slug: formData.slug, delete_status: "", _id: { $ne: propertyId ? propertyId : "" } } }),
        });

        const slugData = await slugResponse.json();

        if (slugData.Status && slugData.Data.length > 0) {
          if (slugData.Data[0].slug === formData.slug) {
            toast.error("Slug already in use for other property.");
            return;
          }
        }

        await submitFormData();
      } catch (error) {
        toast.error("Failed to submit form data.", error);
        console.log("Error", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
    setActiveStep(1);
  };

  const checkExistsInDataBase = async (key, value, msg, table) => {
    try {
      const response = await fetch(url + clientpoints[table], {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, filters: { [key]: value, delete_status: { $in: [true, false] }, _id: { $ne: propertyId ? propertyId : "" } } }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.Count >= 1) {
        toast.warning(`${msg} exists!`);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Error");
      return true;
    }
  };

  console.log("errorsssss", errors);

  const handleSlugBlur = async () => {
    const slug = formData.slug.trim();
    if (slug) {
      const exists = await checkExistsInDataBase("slug", slug, "Slug", "property_count");
      if (exists) {
        setErrors({ ...errors, slug: "Slug Name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, slug: "" });
        setIsSubmittingError(false);
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" && `View ${modalName}`}
                  {mode === "edit" && `Edit ${modalName}`}
                  {mode === "add" && `Add ${modalName}`}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body' style={{ padding: "20px 0 0 0", maxHeight: "90vh" }}>
                    <div className='tabs-container mb-3 mx-2  justify-content-start '>
                      <div className={`tab ${activeStep === 1 ? "active" : ""}`} onClick={() => handleStepChange(1)}>
                        General
                      </div>
                      <div
                        className={`tab ${activeStep === 2 ? "active" : ""}`}
                        onClick={() => {
                          handleStepChange(2);
                        }}
                      >
                        Property Details
                      </div>
                      <div
                        className={`tab ${activeStep === 3 ? "active" : ""}`}
                        onClick={() => {
                          handleStepChange(3);
                          handleSlugBlur();
                        }}
                      >
                        Other Details
                      </div>
                    </div>

                    <div className='' style={{ maxHeight: "60vh", overflowY: "scroll", padding: "0 15px" }}>
                      {/* ------------------------------------------------------------------- */}
                      <div className={activeStep === 1 ? "" : "d-none"}>
                        <h4 className='text-center'>Billing Details </h4>

                        <div className='row'>
                          <div className='col-md-6'>
                            <label htmlFor='legal_company' className='col-form-label'>
                              Legal Company:
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.legal_company ? "is-invalid" : ""}`} id='legal_company' name='legal_company' value={formData.billing_detail.legal_company} onChange={(e) => handleChangeObjectInput(e, "billing_detail")} />
                            {errors.legal_company && <div className='invalid-feedback'>{errors.legal_company}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='billing_address' className='col-form-label'>
                              Billing Address
                            </label>
                            <input
                              disabled={mode === "view"}
                              type='text'
                              className={`form-control ${errors.billing_address ? "is-invalid" : ""}`}
                              id='billing_address'
                              name='billing_address'
                              value={formData.billing_detail.billing_address}
                              onChange={(e) => handleChangeObjectInput(e, "billing_detail")}
                            />
                            {errors.billing_address && <div className='invalid-feedback'>{errors.billing_address}</div>}
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='gstn' className='col-form-label'>
                              GSTIN
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.gstn ? "is-invalid" : ""}`} id='gstn' name='gstn' value={formData.billing_detail.gstn} onChange={(e) => handleChangeObjectInput(e, "billing_detail")} />
                            {errors.gstn && <div className='invalid-feedback'>{errors.gstn}</div>}
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='cin' className='col-form-label'>
                              CIN
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.cin ? "is-invalid" : ""}`} id='cin' name='cin' value={formData.billing_detail.cin} onChange={(e) => handleChangeObjectInput(e, "billing_detail")} />
                            {errors.cin && <div className='invalid-feedback'>{errors.cin}</div>}
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='pan' className='col-form-label'>
                              PAN
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.pan ? "is-invalid" : ""}`} id='pan' name='pan' value={formData.billing_detail.pan} onChange={(e) => handleChangeObjectInput(e, "billing_detail")} />
                            {errors.pan && <div className='invalid-feedback'>{errors.pan}</div>}
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='tan' className='col-form-label'>
                              TAN
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.tan ? "is-invalid" : ""}`} id='tan' name='tan' value={formData.billing_detail.tan} onChange={(e) => handleChangeObjectInput(e, "billing_detail")} />
                            {errors.tan && <div className='invalid-feedback'>{errors.tan}</div>}
                          </div>
                        </div>

                        <hr />

                        <h4 className='text-center'>Bank Details </h4>

                        <div className='row'>
                          <div className='col-md-6'>
                            <label htmlFor='account_name' className='col-form-label'>
                              Account Name:
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.account_name ? "is-invalid" : ""}`} id='account_name' name='account_name' value={formData.bank_detail.account_name} onChange={(e) => handleChangeObjectInput(e, "bank_detail")} />
                            {errors.account_name && <div className='invalid-feedback'>{errors.account_name}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='account_number' className='col-form-label'>
                              Account Number
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.account_number ? "is-invalid" : ""}`} id='account_number' name='account_number' value={formData.bank_detail.account_number} onChange={(e) => handleChangeObjectInput(e, "bank_detail")} />
                            {errors.account_number && <div className='invalid-feedback'>{errors.billing_address}</div>}
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='ifsc' className='col-form-label'>
                              IFSC
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.ifsc ? "is-invalid" : ""}`} id='ifsc' name='ifsc' value={formData.bank_detail.ifsc} onChange={(e) => handleChangeObjectInput(e, "bank_detail")} />
                            {errors.ifsc && <div className='invalid-feedback'>{errors.ifsc}</div>}
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='bank_name' className='col-form-label'>
                              Bank Name
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.bank_name ? "is-invalid" : ""}`} id='bank_name' name='bank_name' value={formData.bank_detail.bank_name} onChange={(e) => handleChangeObjectInput(e, "bank_detail")} />
                            {errors.bank_name && <div className='invalid-feedback'>{errors.bank_name}</div>}
                          </div>{" "}
                        </div>

                        <hr />
                        <h4 className='text-center'>Mail Details</h4>

                        <div className='row'>
                          <div className='col-md-6'>
                            <label htmlFor='smtp_email' className='col-form-label'>
                              SMTP Email
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.smtp_email ? "is-invalid" : ""}`} id='smtp_email' name='smtp_email' value={formData.mail_detail.smtp_email} onChange={(e) => handleChangeObjectInput(e, "mail_detail")} />
                            {errors.smtp_email && <div className='invalid-feedback'>{errors.smtp_email}</div>}
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='smtp_password' className='col-form-label'>
                              SMTP Password
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.smtp_password ? "is-invalid" : ""}`} id='smtp_password' name='smtp_password' value={formData.mail_detail.smtp_password} onChange={(e) => handleChangeObjectInput(e, "mail_detail")} />
                            {errors.smtp_password && <div className='invalid-feedback'>{errors.smtp_password}</div>}
                          </div>{" "}
                        </div>
                      </div>

                      {/* --------------------------------------------------------------------- */}
                      <div className={activeStep === 2 ? "" : "d-none"}>
                        <div className='row'>
                          {/* <div className='col-md-6'>
                          <label htmlFor='segregator' className='col-form-label'>
                           Property Segregator:
                          </label>
                          <select id='segregator' className={`form-select`} value={formData.segregator} onChange={(e)=>setFormData((prev)=>({...prev, segregator: e.target.value}))}>
                            <option value='true'>Client</option>
                            <option value='false'>Demo</option>
                          </select>
                        </div> */}
                          {/* PROPERTY TYPE-------------------------------- */}
                          <div className='col-md-12'>
                            <label htmlFor='property_type' className='col-form-label'>
                              Property Type
                            </label>
                            <Select
                              isDisabled={mode === "view"}
                              required
                              options={propertyTypes?.map((type) => ({ label: type.name, value: type._id }))}
                              value={selectValues.property_type}
                              onChange={(val) =>
                                setFormData((prev) => ({
                                  ...prev,
                                  property_type: val.value,
                                }))
                              }
                              placeholder='Select Property Type...'
                              className='flex-1 w-full'
                              // styles={customStyles}
                              // menuPortalTarget={document.body}
                            />
                            {errors.property_type && <div className='invalid-feedback'>{errors.property_type}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='title' className='col-form-label'>
                              Title
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.title ? "is-invalid" : ""}`} name='title' id='title' value={formData.title} onChange={handleChange} />
                            {errors.title && <div className='invalid-feedback'>{errors.title}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='slug' className='col-form-label'>
                              Slug
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.slug ? "is-invalid" : ""}`} name='slug' id='slug' value={formData.slug} onChange={handleChange} />
                            {errors.slug && <div className='invalid-feedback'>{errors.slug}</div>}
                          </div>
                          <div className='col-md-12'>
                            <label htmlFor='short_description' className='col-form-label'>
                              Short Description
                            </label>
                            <textarea disabled={mode === "view"} rows={4} type='text' className={`form-control ${errors.short_description ? "is-invalid" : ""}`} name='short_description' id='short_description' value={formData.short_description} onChange={handleChange} />
                            {errors.short_description && <div className='invalid-feedback'>{errors.short_description}</div>}
                          </div>
                          <div className='col-md-12'>
                            <label htmlFor='long_description' className='col-form-label'>
                              Long Description
                            </label>
                            <textarea disabled={mode === "view"} rows={4} type='text' className={`form-control ${errors.long_description ? "is-invalid" : ""}`} name='long_description' id='long_description' value={formData.long_description} onChange={handleChange} />
                            {errors.long_description && <div className='invalid-feedback'>{errors.long_description}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='title' className='col-form-label'>
                              Meta Title
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control `} id='title' name='title' value={formData.meta_data.title} onChange={(e) => handleChangeObjectInput(e, "meta_data")} />
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='keywords' className='col-form-label'>
                              Meta Keywords
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control `} id='keywords' name='keywords' value={formData.meta_data.keywords} onChange={(e) => handleChangeObjectInput(e, "meta_data")} />
                          </div>
                          <div className='col-md-12'>
                            <label htmlFor='description' className='col-form-label'>
                              Meta Description
                            </label>
                            <textarea disabled={mode === "view"} rows={3} type='text' className={`form-control `} id='description' name='description' value={formData.meta_data.description} onChange={(e) => handleChangeObjectInput(e, "meta_data")} />
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='contact_email' className='col-form-label'>
                              Contact Email
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.contact_email ? "is-invalid" : ""}`} id='contact_email' name='contact_email' value={formData.contact_email} onChange={handleChange} />
                            {errors.contact_email && <div className='invalid-feedback'>{errors.contact_email}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='contact_number' className='col-form-label'>
                              Contact Number
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control ${errors.contact_number ? "is-invalid" : ""}`} id='contact_number' name='contact_number' value={formData.contact_number} onChange={handleChange} />
                            {errors.contact_number && <div className='invalid-feedback'>{errors.contact_number}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='rating' className='col-form-label'>
                              Rating
                            </label>
                            <select disabled={mode === "view"} id='rating' className={`form-select`} value={formData.rating} onChange={(e) => setFormData((prev) => ({ ...prev, rating: e.target.value }))}>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                            </select>
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='minimum_stay' className='col-form-label'>
                              Minimum Stay
                            </label>
                            <input disabled={mode === "view"} type='number' className={`form-control ${errors.minimum_stay ? "is-invalid" : ""}`} id='minimum_stay' name='minimum_stay' value={formData.minimum_stay} onChange={handleChange} />
                            {errors.minimum_stay && <div className='invalid-feedback'>{errors.minimum_stay}</div>}
                          </div>
                          <div className='d-flex align-items-center gap-3'>
                            <label>
                              <input disabled={mode === "view"} type='checkbox' name='isTop' checked={formData.isTop} onChange={(e) => setFormData((prev) => ({ ...prev, isTop: e.target.checked }))} />
                            </label>
                            Top
                          </div>{" "}
                          <div className='d-flex align-items-center gap-3'>
                            <label>
                              <input disabled={mode === "view"} type='checkbox' name='isActive' checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                            </label>
                            Active
                          </div>{" "}
                          <div className='d-flex align-items-center gap-3'>
                            <label>
                              <input disabled={mode === "view"} type='checkbox' name='hasTax' checked={formData.hasTax} onChange={(e) => setFormData((prev) => ({ ...prev, hasTax: e.target.checked }))} />
                            </label>
                            Tax
                          </div>
                          <div className='d-flex align-items-center gap-3'>
                            <label>
                              <input disabled={mode === "view"} type='checkbox' name='isFeatured' checked={formData.isFeatured} onChange={(e) => setFormData((prev) => ({ ...prev, isFeatured: e.target.checked }))} />
                            </label>
                            Featured
                          </div>
                        </div>
                      </div>

                      {/* ------------------------------------------------------------------------ */}
                      <div className={activeStep === 3 ? "" : "d-none"}>
                        <div className='row'>
                          {/* AREAAAAA----------------------------- */}
                          <div className='col-md-12'>
                            <label htmlFor='area' className='col-form-label'>
                              Area
                            </label>
                            <Select
                              isDisabled={mode === "view"}
                              required
                              options={areas?.map((area) => ({ label: `${area.area_name} (${area.city})`, value: area._id }))}
                              value={selectValues.area}
                              onChange={(val) =>
                                setFormData((prev) => ({
                                  ...prev,
                                  area: val.value,
                                }))
                              }
                              placeholder='Select Area...'
                              // className='flex-1 w-full'
                              // styles={customStyles}
                              // menuPortalTarget={document.body}
                            />
                            {errors.area && <div className='invalid-feedback'>{errors.area}</div>}
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='latitude' className='col-form-label'>
                              Latitude
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control `} id='latitude' name='latitude' value={formData.latitude} onChange={handleChange} />
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='longitude' className='col-form-label'>
                              Longitude
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control `} id='longitude' name='longitude' value={formData.longitude} onChange={handleChange} />
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='property_address' className='col-form-label'>
                              Property Address
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control `} id='property_address' name='property_address' value={formData.property_address} onChange={handleChange} />
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='pincode' className='col-form-label'>
                              Pincode
                            </label>
                            <input disabled={mode === "view"} type='text' className={`form-control `} id='pincode' name='pincode' value={formData.pincode} onChange={handleChange} />
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='checkin_time' className='col-form-label'>
                              Check-in Time
                            </label>
                            <input disabled={mode === "view"} type='time' className={`form-control `} id='checkin_time' name='checkin_time' value={formData.checkin_time} onChange={handleChange} />
                          </div>{" "}
                          <div className='col-md-6'>
                            <label htmlFor='checkout_time' className='col-form-label'>
                              Check-out time
                            </label>
                            <input disabled={mode === "view"} type='time' className={`form-control `} id='checkout_time' name='checkout_time' value={formData.checkout_time} onChange={handleChange} />
                          </div>{" "}
                          <div className='col-md-12 mb-3'>
                            <label htmlFor='collect_key_location' className='col-form-label'>
                              Accessing your apartment where can guests collect the keys?
                            </label>
                            <select disabled={mode === "view"} id='collect_key_location' className={`form-select`} value={formData.collect_key_location} onChange={(e) => setFormData((prev) => ({ ...prev, collect_key_location: e.target.value }))}>
                              <option value='On Location'>On Location</option>
                              <option value='At Different Location'>At Different Location</option>
                            </select>
                          </div>
                          <div className='col-md-12 mb-3'>
                            <MultiCheck disabled={mode === "view"} label='Languages' options={["Hindi", "English", "German", "French"]} selected={formData.languages} name='languages' onCheck={handleSetValue} />
                          </div>
                          <div className='col-md-12 mb-3'>
                            <MultiCheck disabled={mode === "view"} label='Property Amenities' options={amenities} selected={formData.amenities} name='amenities' onCheck={handleSetValue} optionType='object' />
                          </div>
                          <div className='d-flex align-items-center gap-3'>
                            <label>
                              <input disabled={mode === "view"} type='checkbox' name='hasParking' checked={formData.hasParking} onChange={(e) => setFormData((prev) => ({ ...prev, hasParking: e.target.checked }))} />
                            </label>
                            Parking
                          </div>
                          <div className='d-flex align-items-center gap-3'>
                           <label>
                              <input disabled={mode === "view"} type='checkbox' name='pets_allowed' checked={formData.pets_allowed} onChange={(e) => setFormData((prev) => ({ ...prev, pets_allowed: e.target.checked }))} />
                            </label>
                             Pets Allowed
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        {activeStep > 1 && (
                          <button type='button' className='btn btn-secondary' onClick={handlePrevStep} disabled={isSubmittingError}>
                            Previous
                          </button>
                        )}
                        {activeStep < 3 && (
                          <button type='button' className='btn btn-primary' onClick={handleNextStep} disabled={isSubmittingError}>
                            Next
                          </button>
                        )}
                        {activeStep === 3 && mode !== "view" && (
                          <button type='submit' className='btn btn-primary' disabled={isSubmitting || isSubmittingError}>
                            {isSubmitting ? "Loading..." : "Submit"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const initialFormData = {
  billing_detail: {
    legal_company: "",
    billing_address: "",
    gstn: "",
    cin: "",
    pan: "",
    tan: "",
  },
  bank_detail: {
    account_name: "",
    account_number: "",
    ifsc: "",
    bank_name: "",
  },
  mail_detail: {
    smtp_email: "",
    smtp_password: "",
  },
  segregator: "",
  title: "",
  slug: "",
  short_description: "",
  long_description: "",
  meta_data: { title: "", description: "", keywords: "" },
  contact_email: "",
  contact_number: "",
  rating: "",
  minimum_stay: "",
  isTop: true,
  isActive: true,
  hasTax: true,
  isFeatured: true,
  property_type: "",
  latitude: "",
  longitude: "",
  property_address: "",
  area: "",
  pincode: "",
  checkin_time: "",
  checkout_time: "",
  hasParking: true,
  pets_allowed: true,
  languages: [],
  collect_key_location: "",
  amenities: [],
  images: [],
};
