import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import "./component.css";

const formulaObj = { value: "", type: "percentage", operator: "+" };

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [isEditing, setIsEditing] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    color: "#000000",
    description: "",
    isActive: false,
  });

  const [mode, setMode] = useState(view);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let pricing_typeId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    if (additionalFields.length < 0) {
      errors.variant = "Add atleast one variant!";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && pricing_typeId) {
      setLoading(true);
      fetchData(pricing_typeId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, pricing_typeId]);

  const fetchData = async (id) => {
    try {
      const response1 = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      const response2 = await fetch(url + clientpoints.pricing_type_variant_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { pricing_type_id: id } }),
      });

      if (!response1.ok || !response2.ok) {
        navigate(client_prefix);
      }

      const data = await response1.json();
      const variantData = await response2.json();

      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          name: response_data.name,
          isActive: response_data.isActive,
          color: response_data.color,
          description: response_data.description,
        });
      }

      if (variantData.Status && variantData.Data.length > 0) {
        let response_data = variantData.Data;

        console.log("fetched variantssss", response_data);

        setAdditionalFields(response_data);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.pricing_type_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              name: formData.name,
              isActive: formData.isActive,
              color: formData.color,
              description: formData.description,
            },
          }),
        });

        if (!response.ok) {
          navigate(client_prefix);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          if (additionalFields.length > 0) {
            const newVariants = additionalFields.filter((item) => !item._id);
            const variantDataToAdd = newVariants.map((item) => ({ ...item, pricing_type_id: current_id, urlName: hotelId }));

            const response1 = await fetch(url + clientpoints.pricing_type_variant_add, {
              method: "POST",
              headers: pageHeaders,
              credentials: "include",
              body: JSON.stringify(variantDataToAdd),
            });

            if (!response1.ok) {
              throw new Error("Failed to add variants");
            }
          }

          setIsModalOpen(false);
          onRefresh();
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const addField = () => {
    setAdditionalFields((prev) => [...prev, { name: "", description: "", formula: { ...formulaObj } }]);
    setIsEditing((prev) => [...prev, false]); // Initialize with not editing
  };

  const removeField = async (idToDelete, index, type) => {
    console.log("id to delete", idToDelete);
    console.log("index to delete", index);

    const fieldToDelete = additionalFields[index];

    if (!fieldToDelete.name && !idToDelete) {
      const updatedFields = additionalFields.filter((_, i) => i !== index);
      setAdditionalFields(updatedFields);
      const updatedEditing = isEditing.filter((_, i) => i !== index);
      setIsEditing(updatedEditing);
      return;
    }

    let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);

    if (deleteConfirm == type) {
      if (idToDelete) {
        try {
          const response = await fetch(url + clientpoints.pricing_type_variant_delete, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify({
              urlName: hotelId,
              filters: { _id: idToDelete },
            }),
          });
          const responseData = await response.json();

          if (!response.ok) {
            throw new Error("Failed to delete item");
          }

          if (responseData.Status) {
            toast.success(responseData.Message);
            const updatedFields = additionalFields.filter((item, i) => item._id !== idToDelete);
            setAdditionalFields(updatedFields);
            const updatedEditing = isEditing.filter((_, i) => i !== index);
            setIsEditing(updatedEditing);
          } else {
            toast.error(responseData.Message);
          }
        } catch (error) {
          console.error("Error deleting item:", error);
        }
      } else {
        const updatedFields = additionalFields.filter((_, i) => i !== index);
        setAdditionalFields(updatedFields);
        const updatedEditing = isEditing.filter((_, i) => i !== index);
        setIsEditing(updatedEditing);
      }
    } else {
      toast.error("Not Matched");
    }
  };

  const handleChangeDynamicFields = (event, index, key) => {
    const value = event.target.value;
    const updatedFields = [...additionalFields];
    updatedFields[index][key] = value;
    setAdditionalFields(updatedFields);
  };

  const toggleEdit = (index) => {
    const updatedEditing = [...isEditing];
    updatedEditing[index] = !updatedEditing[index];
    setIsEditing(updatedEditing);
  };

  // to update a variant
  const handleSave = async (index) => {
    const fieldToSave = additionalFields[index];

    if (additionalFields[index]._id) {
      // Update on backend

      const response = await fetch(url + clientpoints.pricing_type_variant_edit, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          urlName: hotelId,
          filters: { _id: fieldToSave._id },
          data: {
            ...fieldToSave,
          },
        }),
      });

      if (!response.ok) {
        navigate(client_prefix);
      }

      const responseData = await response.json();

      if (responseData.Status) {
        toast.success("Variant updated!");
      } else {
        toast.error("Error updating variant");
        const response = await fetch(url + clientpoints.pricing_type_variant_view, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: fieldToSave._id },
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const originalData = data.Data[0];

        console.log("failed variant edit data", data);

        setAdditionalFields((prevFields) => prevFields.map((field, i) => (i === index ? originalData : field)));
      }
    }
    toggleEdit(index);
  };

  const handleChangeInput = (e, index) => {
    const [field, subField] = e.target.name.split(".");
    // const value = e.target.type === "number" ? +e.target.value : e.target.value;

    const value = e.target.value;

    const updatedFields = [...additionalFields];
    updatedFields[index].formula[subField] = value;
    setAdditionalFields(updatedFields);
  };

  const multiLevelInputChange = (prev, remNames, value) => {
    const [name1, ...names] = remNames;
    return {
      ...prev,
      [name1]: names?.length > 0 ? multiLevelInputChange(prev[name1], names, value) : value,
    };
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `View ${modalName}` : `Edit ${modalName}`}{" "}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary mx-2 mt-2' onClick={() => setMode("edit")}>
                  Edit
                </button>
              )}
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body' style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <div className='row'>
                      <div className='col-sm-10'>
                        <label htmlFor='name' className='col-form-label'>
                          Name
                        </label>
                        <input disabled={mode === "view"} type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                        {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                      </div>

                      <div className='col-sm-2'>
                        <label htmlFor='name' className='col-form-label'>
                          Color
                        </label>
                        <input disabled={mode === "view"} type='color' className={`form-control `} id='color' name='color' value={formData.color} onChange={handleChange} />
                      </div>

                      <div className='col-sm-12'>
                        <label htmlFor='name' className='col-form-label'>
                          Description
                        </label>
                        <input disabled={mode === "view"} type='text' className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                        {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                      </div>
                      <div className='d-flex align-items-center gap-3'>
                        <label>
                          <input disabled={mode === "view"} type='checkbox' name='isActive' checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                        </label>
                        Active
                      </div>

                      <hr />

                      {additionalFields.map((field, index) => (
                        <div key={index} className='col-md-12 mb-3' style={{ border: "1px solid lightgray", borderRadius: "8px", padding: "5px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <label htmlFor={`additionalField${index}`} id="pricing_font">Pricing Type Variant {index + 1}</label>
                            <div className='d-flex gap-2'>
                              <button disabled={mode === "view"} type='button' className='btn btn-success' style={{ position: "static", height: "fit-content", padding: "8px",margin:" 10px 6px", }} onClick={() => (isEditing[index] ? handleSave(index) : toggleEdit(index))}>
                                {isEditing[index] ? "Save" : "Edit"}
                              </button>
                              <button disabled={mode === "view"} type='button' className='btn btn-danger' style={{ position: "static", height: "fit-content", padding: "8px", margin:" 10px 6px", }} onClick={() => removeField(field._id, index, field.name)}>
                                Remove
                              </button>
                            </div>
                          </div>
                          <div className='input-group'>
                            <input
                              disabled={!isEditing[index] || mode === "view"}
                              placeholder='Name'
                              type='text'
                              className='form-control'
                              id={`additionalFieldName${index}`}
                              name={`additionalFieldName${index}`}
                              value={field.name}
                              onChange={(event) => handleChangeDynamicFields(event, index, "name")}
                            />
                          </div>
                          <textarea
                            disabled={!isEditing[index] || mode === "view"}
                            placeholder='Description'
                            rows={3}
                            className='form-control'
                            id={`additionalFieldDescription${index}`}
                            name={`additionalFieldDescription${index}`}
                            value={field.description}
                            onChange={(event) => handleChangeDynamicFields(event, index, "description")}
                          />
                          <FormulaBox disabled={!isEditing[index] || mode === "view"} name={`additionalFieldFormula${index}`} value={field.formula} onChange={(e) => handleChangeInput(e, index)} />
                        </div>
                      ))}

                      <div className='col-md-12'>
                        <button disabled={mode === "view"} type='button' className='btn btn-success' onClick={addField}>
                          Add Variant
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    {mode !== "view" && (
                      <>
                        <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                          Close
                        </button>
                        {/* <button type="submit" className="btn btn-primary">Update</button> */}
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const FormulaBox = ({ name = "", value = {}, onChange = () => {}, disabled }) => {
  return (
    <>
        <div className="row">
        <label className='fw-semibold'>Default Formula</label>
        <div className='col-sm-4'>
        <NormalInput
          disabled={disabled}
          placeholder='Enter value...'
          type='number'
          name={name + ".value"}
          value={value.value}
          onChange={onChange}
          // min=''
        />
        </div>
        <div className='col-sm-4'>
        <NormalSelect disabled={disabled} name={name + ".type"} value={value.type} onChange={onChange} options={typeOptions} optionType='object' optionValue='value' />
        </div>
        <div className='col-sm-4'>
        <NormalSelect disabled={disabled} name={name + ".operator"} value={value.operator} onChange={onChange} options={operatorOptions} optionType='object' optionValue='value' />
        </div>
      </div>
    </>
  );
};

export const NormalSelect = ({ label = "", name = "", disabled = false, value = "", onChange, options = [], optionType = "string", optionLabel = "name", optionValue = "_id" }) => {
  return (
    <div className=''>
      {label && <label className='form-label fw-semibold text-muted'>{label}</label>}
      <select disabled={disabled} name={name} value={value} onChange={onChange} className='form-select'>
        {options.map((item, i) => (
          <option key={i} value={optionType === "string" ? item : item[optionValue]}>
            {optionType === "string" ? item : item[optionLabel]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const NormalInput = ({ label, type = "text", name = "", disabled = false, readOnly = false, value = "", onChange, min = "", max = "", inputStyles = {}, placeholder = "" }) => {
  return (
    <div className=''>
      {label && <label className='form-label fw-semibold text-muted'>{label}</label>}
      <input type={type} placeholder={placeholder} name={name} disabled={disabled} readOnly={readOnly} value={value} onChange={onChange} min={min} max={max} className={`form-control ${disabled ? "bg-light text-muted" : ""} ${readOnly ? "bg-light" : ""}`} style={inputStyles} />
    </div>
  );
};

const typeOptions = [
  {
    name: "Percentage",
    value: "percentage",
  },
  {
    name: "Fixed",
    value: "fixed",
  },
];
const operatorOptions = [
  {
    name: "Plus (+)",
    value: "+",
  },
  {
    name: "Minus (-)",
    value: "-",
  },
];
