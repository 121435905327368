import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { SortOutlined } from "@mui/icons-material";

const formulaObj = { value: "", type: "percentage", operator: "+" };

const multiLevelInputChange = (prev, remNames, value) => {
  const [name1, ...names] = remNames;
  return {
    ...prev,
    [name1]: names?.length > 0 ? multiLevelInputChange(prev[name1], names, value) : value,
  };
};

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName }) {
  const navigate = useNavigate();
  const [view, setView] = useState("add");
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const initialFormData = {
    extra_guest_price: {
      start_after_guest: 3,
      price: "",
    },
    price_variant_formulas: [],
    discount: {
      days1to6: formulaObj,
      days7to27: formulaObj,
      days14to27: formulaObj,
      daysMoreThan27: formulaObj,
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [pricingTypeVariants, setPricingTypeVariants] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let roomId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // if (!formData.question.trim()) {
    //   errors.question = "Question is required";
    //   isValid = false;
    // }

    // if (!formData.answer.trim()) {
    //   errors.answer = "Answer is required";
    //   isValid = false;
    // }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  console.log("room idddd", roomId);

  useEffect(() => {
    if (isModalOpen && roomId) {
      setLoading(true);
      fetchData(roomId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, roomId]);

  const fetchData = async (id) => {
    try {
      const formulaResponse = await fetch(url + clientpoints.room_price_formula_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { room_id: id, delete_status: false } }),
      });

      const variantsResponse = await fetch(url + clientpoints.pricing_type_variant_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, sorting: { pricing_type_id: -1 }, filters: { delete_status: false } }),
      });

      if (!formulaResponse.ok) {
        throw new Error("Failed to fetch formula data");
      }

      if (!variantsResponse.ok) {
        throw new Error("Failed to fetch variants data");
      }

      const formulaData = await formulaResponse.json();
      const variantsData = await variantsResponse.json();

      console.log("formula response-----", formulaData);
      console.log("variants response-----", variantsData);

      if (formulaData.Status && formulaData.Data.length > 0) {
        let response_data = formulaData.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          ...response_data,
          extra_guest_price: { ...response_data.extra_guest_price },
          discount: { ...response_data.discount },
          price_variant_formulas: variantsData.Data.map((item) => {
            // Find corresponding formula in formulaData
            const correspondingFormula = formulaData.Data[0].price_variant_formulas.find((f) => f.pricing_type_variant_id == item._id);

            return {
              pricing_type_variant_id: {
                _id: item._id,
                name: item.name,
                pricingType: item.pricing_type_id.name,
              },
              formula: correspondingFormula
                ? {
                    value: correspondingFormula.formula.value,
                    type: correspondingFormula.formula.type,
                    operator: correspondingFormula.formula.operator,
                  }
                : {
                    value: item.formula.value,
                    type: item.formula.type,
                    operator: item.formula.operator,
                  },
            };
          }),

          // price_variant_formulas: response_data.price_variant_formulas || [], // Ensure we have an array
        });

        console.log("formula foundddd");
        setView("edit");
      } else {
        setView("add");
        console.log("formula not found-----------------");
      }

      if (variantsData.Status && variantsData.Data.length > 0) {
        let response_data = variantsData.Data;

        setPricingTypeVariants(response_data);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  console.log("form dataaaa", formData);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let apiEndpoint = "";
    let filters = {};

    let requestBody = {};

    if (view === "add") {
      apiEndpoint = clientpoints.room_price_formula_add;
      requestBody = { urlName: hotelId, room_id: roomId, ...formData };
    } else if (view === "edit") {
      apiEndpoint = clientpoints.room_price_formula_edit;
      (filters = { room_id: roomId, _id: current_id }),
        (requestBody = {
          urlName: hotelId,
          filters: filters,
          data: { ...formData },
        });
    }

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + apiEndpoint, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const handleChangeInput = (e) => {
    const [name1, ...names] = e.target.name.split(".");
    const value = e.target.type === "number" ? +e.target.value : e.target.value;

    setFormData((prev) => ({
      ...prev,
      [name1]: names?.length > 0 ? multiLevelInputChange(prev[name1], names, value) : value,
    }));
  };

  useEffect(() => {
    if (pricingTypeVariants.length > 0 && view === "add") {
      setFormData((prev) => ({
        ...prev,
        price_variant_formulas: pricingTypeVariants.map((item) => ({
          pricing_type_variant_id: { _id: item._id, name: item.name, pricingType: item.pricing_type_id.name },
          // formula: formulaObj,
          formula: {
            value: item.formula.value,
            type: item.formula.type,
            operator: item.formula.operator,
          },
        })),
      }));
    }
  }, [pricingTypeVariants, view]);

  // console.log("formDataaaaa", formData);

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block", zIndex: 1000000000 }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {view === "add" ? "Add Price Formula" : "Edit Price Formula"}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body" style={{ maxHeight: "70vh", scrollY: "auto" }}>
                    <div className="row">
                      {/* <div className="col-md-4 col-sm-6 col-12 d-flex flex-column gap-4 mb-3">
                        {" "}
                        <label className="form-label fw-semibold">Extra Guest Price After</label>
                        <NormalSelect
                          name="extra_guest_price.start_after_guest"
                          value={formData.extra_guest_price.start_after_guest}
                          onChange={(e) =>
                            handleChangeInput({
                              target: { name: e.target.name, value: +e.target.value },
                            })
                          }
                          options={Array.from({ length: 10 }, (_, i) => i + 1)}
                        />
                        <NormalInput type="number" name="extra_guest_price.price" value={formData.extra_guest_price.price} onChange={handleChangeInput} />
                      </div> */}

                      {formData.price_variant_formulas?.map((item, i) => (
                        <PriceForVariant item={item} key={i} setFormData={setFormData} />
                      ))}

                      {/* <FormulaBox label='Discount Amount for 1 to 6 days' name='discount.days1to6' value={formData.discount.days1to6} onChange={handleChangeInput} />
                      <FormulaBox label='Discount Amount for 7 to 27 days' name='discount.days7to27' value={formData.discount.days7to27} onChange={handleChangeInput} />
                      <FormulaBox label='Discount Amount for 14 to 27 days' name='discount.days14to27' value={formData.discount.days14to27} onChange={handleChangeInput} />
                      <FormulaBox label='Discount Amount for more than 27 days' name='discount.daysMoreThan27' value={formData.discount.daysMoreThan27} onChange={handleChangeInput} /> */}
                    </div>
                  </div>
                )}
                <div className="modal-footer d-flex justify-content-between align-items-center">
                  {view === "edit" ? <p className="float-start">CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p> : <p></p>}
                  <div className="d-flex gap-2">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                      Close
                    </button>
                    {/* <button type="submit" className="btn btn-primary">Update</button> */}
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? "Loading..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const PriceForVariant = ({ item, setFormData }) => {
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    const [id, key] = name.split(".");

    setFormData((prev) => ({
      ...prev,
      price_variant_formulas: prev.price_variant_formulas.map((variant) => (variant.pricing_type_variant_id._id === id ? { ...variant, formula: { ...variant.formula, [key]: value } } : variant)),
    }));
  };

  return (
    <FormulaBox label={`${item.pricing_type_variant_id.pricingType} ${item.pricing_type_variant_id.name}`} name={item.pricing_type_variant_id._id} value={item.formula} onChange={handleChangeInput} />
  );
};

const FormulaBox = ({ label = "", name = "", value = {}, onChange = () => {} }) => {
  return (
    <>
      <div className="col-sm-6 col-12 d-flex flex-column gap-2">
        <label className="fw-semibold">{label}</label>
        <NormalInput
          placeholder="Enter value..."
          type="number"
          name={name + ".value"}
          value={value.value}
          onChange={onChange}
          // min=''
        />
        <NormalSelect name={name + ".type"} value={value.type} onChange={onChange} options={typeOptions} optionType="object" optionValue="value" />
        <NormalSelect name={name + ".operator"} value={value.operator} onChange={onChange} options={operatorOptions} optionType="object" optionValue="value" />
      </div>
    </>
  );
};

export const NormalSelect = ({ label = "", name = "", disabled = false, value = "", onChange, options = [], optionType = "string", optionLabel = "name", optionValue = "_id" }) => {
  return (
    <div className="">
      {label && <label className="form-label fw-semibold text-muted">{label}</label>}
      <select name={name} disabled={disabled} value={value} onChange={onChange} className="form-select">
        {options.map((item, i) => (
          <option key={i} value={optionType === "string" ? item : item[optionValue]}>
            {optionType === "string" ? item : item[optionLabel]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const NormalInput = ({ label, type = "text", name = "", disabled = false, readOnly = false, value = "", onChange, min = "", max = "", inputStyles = {}, placeholder = "" }) => {
  return (
    <div className="">
      {label && <label className="form-label fw-semibold text-muted">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        className={`form-control ${disabled ? "bg-light text-muted" : ""} ${readOnly ? "bg-light" : ""}`}
        style={inputStyles}
      />
    </div>
  );
};

const typeOptions = [
  {
    name: "Percentage",
    value: "percentage",
  },
  {
    name: "Fixed",
    value: "fixed",
  },
];
const operatorOptions = [
  {
    name: "Plus (+)",
    value: "+",
  },
  {
    name: "Minus (-)",
    value: "-",
  },
];
